import React from "react";
import "./SingleInput.css";
function SingleInput(props) {
  return (
    <div className="input">
      <div style={props?.inputLabelStyle}>
        <img src={props.src} style={props?.iconStyle} />
        <p>{props.label}</p>
      </div>
      <input
        style={props?.inputStyle}
        ref={props.myref}
        type={props.type}
        //onChange={props.handleInput}
        required={props.required}
        placeholder={props.placeholder}
        defaultValue={props.value}
        readOnly={props.readOnly}
      />
    </div>
  );
}

export default SingleInput;
