import React from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
// Require Font Awesome.
import "font-awesome/css/font-awesome.css";
// Import all Froala Editor plugins;
import "froala-editor/js/plugins.pkgd.min.js";
// Import a single Froala Editor plugin.
import "froala-editor/js/plugins/align.min.js";
// Import a language file.
import "froala-editor/js/languages/de.js";
// Import a third-party plugin.
import "froala-editor/js/third_party/image_tui.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/third_party/spell_checker.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import FroalaEditor from "react-froala-wysiwyg";

import { useEffect, useState, useRef } from "react";
import "./EditProject.css";
import "quill/dist/quill.snow.css";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import qrIcon from "../../Assets/fi-rr-vector.png";

import { SectionAPIs, TeamProduct, authAPIs } from "../../api";

import BigModal from "../Form/BigModal";
import DeleteSection from "./DeleteSection";
import AddSection from "./AddSection";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Success/SuccessMessage";

import check2 from "../../Assets/check2.png";
import folder from "../../Assets/folder.png";
import plus from "../../Assets/plus.png";
import cross2 from "../../Assets/cross2.png";
import AddQr from "./AddQr";

function FroalaEditProject() {
  const ProdcutId = useParams().id;
  const currentdeleteid = useRef();
  const message = useRef();
  const intervalRef = useRef();

  const [current, setCurrent] = useState(0);
  const [sections, setSections] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [add, setAdd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [change, setChange] = useState(false);
  const [save, setSave] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [open, setOpen] = useState(false);
  const [qr, setQr] = useState("");
  const [productData, setProductData] = useState("");
  const [s3Hash, setS3Hash] = useState({});

  //Processing State
  const [saving, setSaving] = useState(false);
  const [savingAsDraft, setSavingAsDraft] = useState(false);
  const [publishing, setPublishing] = useState(false);

  async function getSections() {
    try {
      const raw = await fetch(`${SectionAPIs.getSections(ProdcutId)}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  const removeSection = (index) => {
    if (index === 0 && !sections.length) setCurrent(0);
    else setCurrent(index - 1);
    setSections([
      ...sections.slice(0, index),
      ...sections.slice(index + 1, sections.length),
    ]);
  };

  async function updateSectionsContent() {
    try {
      setSaving(true);
      if (!sections.length) throw { message: "Please create the section!" };

      const raw = await fetch(`${TeamProduct.saveSections(productData._id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          sections,
          comment: productData.comment,
          saveAsPublic: true,
        }),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  /**
   * @description save sections content in draft
   */
  async function saveAsDraft() {
    try {
      setSavingAsDraft(true);
      if (!sections.length) throw { message: "Please create the section!" };

      const raw = await fetch(`${TeamProduct.saveSections(productData._id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify({
          sections,
          comment: productData.comment,
          saveAsPublic: false,
        }),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function publishProject() {
    try {
      setPublishing(true);
      const raw = await fetch(`${TeamProduct.editProducts(productData._id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify({ active: true }),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function draftProject() {
    try {
      setPublishing(true);
      const raw = await fetch(`${TeamProduct.editProducts(productData._id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify({ active: false }),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function getS3Hash() {
    try {
      const raw = await fetch(`${authAPIs.getS3Hash}`, {
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  //
  function UpdateContent() {
    updateSectionsContent()
      .then((response) => {
        message.current = "Product Saved Successfully";
        setSave(true);
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      })
      .finally(() => setSaving(false));
  }

  function SaveAsDraft() {
    saveAsDraft()
      .then((response) => {
        message.current = "Product Saved as draft Successfully";
        setSave(true);
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      })
      .finally(() => setSavingAsDraft(false));
  }

  function PublishProject() {
    publishProject()
      .then((response) => {
        message.current = "Product Published Successfully";
        setSave(true);
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      })
      .finally(() => setPublishing(false));
  }

  function DraftProject() {
    draftProject()
      .then((response) => {
        message.current = "Product unpublished Successfully";
        setSave(true);
      })
      .catch((err) => {
        message.current = err.message;
        setPasswordError(true);
      })
      .finally(() => setPublishing(false));
  }

  useEffect(() => {
    //get hash
    getS3Hash()
      .then((response) => {
        setS3Hash(response.data.s3Hash);
      })
      .catch((err) => {
        alert("Failed to get hash!");
      });
    //get section
    getSections().then((response) => {
      setProductData(response?.data?.product);
      setQr(response?.data?.product?.qrcode);
      setSections(response?.data?.product?.sections);
    });
  }, [save]);

  useEffect(() => {
    //refresh hash every 4 minutes
    intervalRef.current = setInterval(() => {
      getS3Hash()
        .then((response) => {
          setS3Hash(response.data.s3Hash);
        })
        .catch((err) => {
          message.current =
            "Something went wrong!. Please save your changes in draft and refresh the page.";
          setPasswordError(true);
        });
    }, 3 * 60 * 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  const updateNewSection = (section) => {
    setSections([...sections, section]);
    setAdd(false);
  };

  return (
    <div className="edit-project">
      <h2>Edit {productData.name}</h2>
      {/* <p>The follow table shows relatable information towards different Customer</p> */}
      <div className="btns">
        {/**comment*/}
        <div className="save">
          <input
            type="text"
            placeholder="Version history to be here"
            onInput={(e) => {
              productData.comment = e.target.value;
              setProductData({ ...productData });
            }}
            value={productData.comment}
          />
        </div>
        <div className="tool-tip">
          <div>
          <button onClick={UpdateContent}>
            {saving ? "Saving.." : "Save"}
          </button>
          </div>
          <span>
            <u>Save:</u> Using this you can save the manual, and the changes
            would be visible instantly to the users.
          </span>
        </div>
        <div className="tool-tip">
          <div>
            <button onClick={SaveAsDraft}>
              {savingAsDraft ? "Saving.." : "Save as Draft"}
            </button>
          </div>
          <span>
            <u>Save As Draft</u>: Using this you can save the manual as draft,
            changes would be visible only to the manufacturer in the
            application.
          </span>
        </div>
        {productData.active ? (
          <div className="tool-tip">
            <div className="btn-middle">
              <img alt="folder" src={folder} />
              <button onClick={DraftProject}>
                {publishing ? "Unpublishing..." : "Unpublish"}
              </button>
            </div>
            <span>
              <u>Publish/Unpublish</u>: Using this you can publish the manual so
              that user can find and add it to their inventory or unpublish it
              to hide it.
            </span>
          </div>
        ) : (
          <div className="tool-tip">
            <div className="btn-left">
              <img alt={"check2"} src={check2} />
              <button onClick={PublishProject}>
                {publishing ? "Publishing..." : "Publish"}
              </button>
            </div>
            <span>
              <u>Publish/Unpublish</u>: Using this you can publish the manual so
              that user can find and add it to their inventory or unpublish it
              to hide it.
            </span>
          </div>
        )}

        <div className="tool-tip">
          <div className="btn-right">
            <img alt={"qricon"} src={qrIcon} />
            <button onClick={() => setOpen(true)}>Show QR</button>
          </div>
          <span>
            <u>Show QR</u>: This will show you the QR code, which you can
            download and print and put it on the product.
          </span>
        </div>
      </div>
      <div className="section">
        {s3Hash &&
          sections.map((data, index) => {
            return (
              <div className="section-div">
                <p
                  onClick={() => setCurrent(index)}
                  style={
                    current === index ? { borderBottom: "2px solid green" } : {}
                  }
                >
                  {data.name}
                </p>
                <img
                  alt="cross"
                  src={plus}
                  onClick={() => {
                    currentdeleteid.current = data._id;
                    setDeleting(true);
                    setCurrentIndex(index);
                  }}
                />
              </div>
            );
          })}
        <div
          className="add-section"
          onClick={() => {
            setAdd(true);
          }}
        >
          <img alt={"cross2"} src={cross2} />
          <p>Add Section</p>
        </div>
      </div>
      <div className="project">
        <div className="bg" />
        {/**
         * toolbarButtons: [
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "strikeThrough",
                  "subscript",
                  "superscript",
                  "outdent",
                  "indent",
                  "clearFormatting",
                  "insertTable",
                  "html",
                  "file",
                  "insertImage",
                  "insertVideo",
                  "undo",
                  "redo",
                ],
         */}
        <div className="content-wrapper">
          {sections.length ? (
            <FroalaEditor
              model={sections[current]?.draftContent || ""}
              onModelChange={(value) => {
                if (sections[current]) {
                  sections[current].draftContent = value;
                }
              }}
              config={{
                toolbarButtons: [
                  "bold",
                  "italic",
                  "alignLeft",
                  "alignCenter",
                  "markdown",
                  "undo",
                  "redo",
                  "underline",
                  "strikeThrough",
                  "subscript",
                  "superscript",
                  "fontFamily",
                  "fontSize",
                  "textColor",
                  "backgroundColor",
                  "inlineClass",
                  "inlineStyle",
                  "clearFormatting",
                  "formatOLSimple",
                  "alignRight",
                  "alignJustify",
                  "formatOL",
                  "formatOLOptions",
                  "formatUL",
                  "formatULOptions",
                  "paragraphFormat",
                  "paragraphStyle",
                  "lineHeight",
                  "outdent",
                  "indent",
                  "quote",
                  "insertLink",
                  "insertFiles",
                  "insertImage",
                  "insertVideo",
                  "insertTable",
                  "emoticons",
                  "fontAwesome",
                  "specialCharacters",
                  "embedly",
                  "insertFile",
                  "insertHR",
                  "fullscreen",
                  "print",
                  "getPDF",
                  "spellChecker",
                  "selectAll",
                  "html",
                  "help",
                ],
                key: "sZH1rB1B6D7B5B4F4E4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3B11B1C6E5B1F4C3==",
                placeholder: "Edit Me",
                toolbarSticky: false,
                charCounterCount: false,
                attribution: false,
                imageUploadToS3: s3Hash,
                imageMaxSize: 10 * 1024 * 1024,
                videoUploadToS3: s3Hash,
                videoMaxSize: 500 * 1024 * 1024,
              }}
            />
          ) : (
            <p style={{ display: "flex", color: "grey", margin: "auto" }}>
              Create section
            </p>
          )}
        </div>
      </div>
      {open ? (
        <BigModal>
          <AddQr
            userData={(value) => {
              setOpen(false);
            }}
            qrdata={qr}
          />
        </BigModal>
      ) : (
        ""
      )}
      {deleting ? (
        <BigModal
          style={{ width: "425px", height: "290px", marginTop: "254.15px" }}
        >
          <DeleteSection
            id={currentdeleteid.current}
            deleteData={(value) => {
              setDeleting(false);
              if (value) {
                removeSection(currentIndex);
              }
            }}
            sectionName={sections[currentIndex].name}
          />
        </BigModal>
      ) : (
        ""
      )}
      {add ? (
        <BigModal
          style={{
            width: "425px",
            height: "380px",
            marginTop: "210px",
            zIndex: "10",
          }}
        >
          <AddSection
            id={currentdeleteid.current}
            onAdding={(value) => {
              setAdd(false);
              setChange(!change);
            }}
            ProdcutId={ProdcutId}
            setSections={updateNewSection}
          />
        </BigModal>
      ) : (
        ""
      )}
      {save ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <SuccessMessage
            closeSuccess={() => {
              setSave(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}

      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default FroalaEditProject;
