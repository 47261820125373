import React, {useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import './SignupStep.css';

import About from './Steps/About';
import Address from './Steps/Address';
import CompanyDetail from './Steps/CompanyDetail';
import Subscription from './Steps/Subscription';
import Payment from './Steps/Payment';

import user from '../../Assets/user.svg';
import SignupForm from './SignupForm';
import SignupComplete from './SignupComplete';

function SignupStep(props) {
  const {current} = props;
  const isBig = useMediaQuery({
    query: '(min-width: 800px)'
  })
  //const [current,SetCurrent] = useState(props.current);
  
  const currentstyle={
    filter: 'invert(85%) sepia(14%) saturate(996%) hue-rotate(98deg) brightness(86%) contrast(87%)'
  };
  const currentlistyle={
    color:'#77CAA6',
    backgroundColor:'#FFFFFF',
    boxShadow:' 0px 3px 7px #BFE1FF',
  };
  const circlestyle = {
    borderTopColor:((current>0)?'#77CAA6':'rgb(221, 242, 249)'),
    borderRightColor:((current>1)?'#77CAA6':'rgb(221, 242, 249)'),
    borderBottomColor:((current>2)?'#77CAA6':'rgb(221, 242, 249)'),
    borderLeftColor:((current>2)?'#77CAA6':'rgb(221, 242, 249)'),
  }
  function GetData(data){
    if(current === 4){
      props.isStepComplete(true);
    }
    props.setCurrent((data?.current)?(data?.current):current);
  }

  console.log(current)
  return (
    <div className="signup-step">
      {isBig && <div className="left-side">
        <div className="page-circle" style={circlestyle}> {current<3?current:3}/3 </div>
        <p>Complete The Following Steps</p>
        <ul>
          <li style={current === 1?currentlistyle:{}} ><img style={current === 1?currentstyle:{}} src={user} />About</li>
          {/* <li style={current === 2?currentlistyle:{}} ><img style={current === 2?currentstyle:{}} src={user} />Address</li> */}
          <li style={current === 2?currentlistyle:{}} ><img style={current === 3?currentstyle:{}} src={user} />Company Detail</li>
          <li style={current > 2?currentlistyle:{}} ><img style={current>3?currentstyle:{}} src={user} />Subscription</li>
        </ul>
      </div> }
      <div className="right-side">
        {(current === 0) && <p style={{textAlign:"center"}}>Please wait! Loading page</p>}
        {(current === 1) && <About GiveAboutData={GetData}/>} 
        {(current === 2) && <CompanyDetail GiveCompanyData={GetData}/>} 
        {(current === 3) && <Subscription GiveSubscriptionData={GetData}/>} 
        {(current === 4) && <Payment GivePaymentData={GetData}/>}
        {(current === 5) && <SignupComplete />}
      </div>
    </div>
  )
}

export default SignupStep;