import React from "react";

import Header from "../Components/Header/Header";
import LeftNavigationBar from "../Components/Navigation/LeftNavigationBar";
import TeamProductCard from "../Components/TeamProduct/TeamProductCard";

function TeamProduct() {
  return (
    <div style={{ marginTop: "79px", display: "flex" }}>
      <Header />
      <LeftNavigationBar />
      <TeamProductCard />
    </div>
  );
}

export default TeamProduct;
