import React, { useState, useContext, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authAPIs } from "../../api";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({ data: null });

  const token = window.localStorage.getItem("token");

  const checkAuth = async () => {
    try {
      const raw = await fetch(authAPIs.validateToken, {
        method: "GET",
        headers: { token },
      });

      const res = await raw.json();

      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  useEffect(() => {
    const openRoutes = ["/", "/signup", "/forgot-password"];
    const isOpenRoute = openRoutes.includes(window.location.pathname);
    const subClientRestrictedRoutes = ["/teamtable"];
    const isSubClientRestrictedRoutes = subClientRestrictedRoutes.includes(
      window.location.pathname
    );

    if (!token && isOpenRoute) return;

    checkAuth()
      .then((response) => {
        if (response.status !== "success") throw response;

        setData(response.data);
        const user = response.data.user;

        //if logged client company details and subscription not provide redirect it to signup page
        if (
          user.role === "client" &&
          (!user.companyId || !user.billingAccountEnabled)
        ) {
          navigate("/signup");
          return;
        }

        //redirect logged subclient to team product
        if (user.role === "subclient") {
          if (isSubClientRestrictedRoutes) {
            navigate("/teamproduct");
          }
          return;
        }

        if (isOpenRoute) {
          navigate("/teamtable");
          return;
        }
      })
      .catch(() => {
        //redirect user to login page if token expires
        navigate("/");
        localStorage.removeItem("token");
      });
  }, [token, navigate]);

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
