import React, { useEffect, useState } from "react";
import "./GetSubscriptions.css";
import { SubscriptionAPIs, SubscriptionAPIsv2 } from "../../../api";

function GetSubscriptions(props) {
  const [activeSub, setActiveSub] = useState([]);
  const [loading, setLoading] = useState(true);
  function onSubmit() {
    props.GiveData("HELLO");
  }

  async function GetSub() {
    try {
      const raw = await fetch(
        `${SubscriptionAPIsv2.subscriptions}`,
        {
          method: "GET",
          headers: { token: localStorage.getItem("token") },
        }
      );
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      setLoading(false);
      throw err;
    }
  }
  useEffect(() => {
    GetSub().then((response) => {
      setActiveSub(response?.data?.subscriptions || []);
      setLoading(false);
    });
  }, []);

  function days(end, start) {
    return Math.ceil((end - start) / (24 * 60 * 60));
    /*const date1 = new Date();
    const date2 = new Date(date22);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + " days";*/
  }

  return (
    <div className="subscriptions">
      <div className="close-container">
        <span onClick={() => props.close(false)} className="fa fa-times"></span>
      </div>
      <h2>Choose Subscription</h2>
      {/* <p><button onClick={onSubmit}>Buy New Subscription</button></p> */}
      <div className="main-container">
      
        {loading? <span>Please wait, Fetching available subscriptions</span>:
         activeSub.length > 0 ? (
          <div className="subscription-table">
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "50px",
                      paddingLeft: "13px",
                      textAlign: "left",
                    }}
                  >
                    <label for="employee">Sr.No.</label>
                  </th>
                  <th style={{ width: "154px" }}>Products</th>
                  <th style={{ width: "139px" }}>Expiring in</th>
                  <th style={{ width: "154px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {activeSub.map((data, id) => (
                  <>
                    <tr key={id}>
                      <td
                        style={{
                          width: "50px",
                          paddingLeft: "13px",
                          textAlign: "left",
                        }}
                      >
                        {id++ + 1}
                      </td>
                      <td
                        style={{ width: "154px" }}
                      >{`${data.usedProduct}/${data?.plan[0]?.quantity}`}</td>
                      <td style={{ width: "136px" }}>
                        {days(
                          data.current_period_end,
                          data.current_period_start
                        )}
                      </td>

                      {data.usedProduct === data?.plan[0]?.quantity ? <td>unavailable</td>:
                      <td
                        style={{ width: "154px" }}
                        onClick={() =>
                          props.GiveData({
                            subscriptionId: data.id,
                            current: 5,
                          })
                        }
                      >
                        {" "}
                        <button>Use Subscription</button>
                      </td>
}
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
            <button onClick={onSubmit}>Buy New Subscription</button>
          </div>
        ) : (
          <div className="subscription-table">
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "50px",
                      paddingLeft: "13px",
                      textAlign: "left",
                    }}
                  >
                    <label for="employee">Sr.No.</label>
                  </th>
                  <th style={{ width: "154px" }}>Products</th>
                  <th style={{ width: "139px" }}>Expiring in</th>
                  <th style={{ width: "154px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                <p>No Sub</p>
              </tbody>
            </table>
            <button onClick={onSubmit}>Buy New Subscription</button>
          </div>
        )}

      </div>
    </div>
  );
}

export default GetSubscriptions;
