import React from 'react'
import Background from '../Background/Background';
import './UseForm.css';
function UseForm(props) {
  return (
    <div style={{margin:0}}>
        <Background/>
        <div className="useform">
            {props.children}
        </div>
    </div>
  )
}

export default UseForm;