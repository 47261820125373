import React, { useState } from "react";
import "./Header.css";

// import SearchBar from '../SearchBar/SearchBar';
import TopIcon from "../../Assets/Green 1.png";
import topRight from "../../Assets/topRight.png";
import { useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  async function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
    localStorage.removeItem("companyId");
    localStorage.removeItem("isSubscription");
    navigate("/");
  }
  return (
    <>
      <div className="header">
        <img
          className="topicon"
          onClick={() => navigate("/teamtable")}
          src={TopIcon}
          alt="logo"
        />
        <p className="title" onClick={() => navigate("/teamtable")}>
          THE GREEN MANUAL
        </p>
        <div className="search">
          {/* <SearchBar placeholder="Search for keywords"/> */}
        </div>
        {/* <img className="bell" src={bell} alt="logo" /> */}
        <div className="topright" onClick={() => setOpen(!open)}>
          <img src={topRight} alt="logo" />
        </div>
      </div>
      {open && (
        <div className="dropdown">
          <ul>
            <li onClick={() => navigate("/profile")}>Profile</li>
            <li onClick={() => handleLogout()}>Logout</li>
          </ul>
        </div>
      )}
    </>
  );
}

export default Header;
