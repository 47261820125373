import React, { useRef, useState, useEffect } from "react";
import Select from "../../Form/v2/Input/Select";

import "./AddingProduct.css";

import user from "../../../Assets/user.svg";

import ErrorMessage from "../../Error/ErrorMessage";
import BigModal from "../../Form/BigModal";
import Form from "../../Form/v2/Form/Form";
import Input from "../../Form/v2/Input/Input";
import TextArea from "../../Form/v2/Input/TextArea";

import { TeamProduct } from "../../../api";

function AddingProduct(props) {
  const [subscriptionId, setSubscriptionId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubcategory] = useState({});
  const [product, setProduct] = useState({});

  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);

  const handleInput = (e) => {
    product[e.target.name] = e.target.value;
    setProduct({ ...product });
  };

  useEffect(() => {
    if (props?.data?.current === 5) {
      setSubscriptionId(props?.data?.subscriptionId);
    } else {
      setSubscriptionId(props?.data);
    }
  }, []);
  async function AddProduct(PaymentData) {
    try {
      const raw = await fetch(`${TeamProduct.addProducts}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify(PaymentData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  function onSubmit() {
    const PaymentData = {
      subscriptionId: subscriptionId,
      category: selectedCategory,
      subcategory: selectedSubCategory.name,
      ...product,
    };
    AddProduct(PaymentData)
      .then((response) => {
        props.GiveData(response.data.product);
      })
      .catch((err) => {
        message.current = err.message;
        setPasswordError(true);
      });
  }

  const getSubcategories = (selectedCategory) => {
    if (!selectedCategory) return [];
    const category = props.categories.filter(
      (category) => category.category === selectedCategory
    )[0];
    if (!category) return [];

    return category.subcategories.map((subcategory) => {
      return {
        name: subcategory.subcategory,
        value: subcategory._id,
      };
    });
  };

  const inputStyle = {
    width: "811px",
    paddingTop: "12px",
    height: "73px",
    background: "#F5F5F5",
    borderRadius: "16px",
  };
  const inputLabelStyle = {
    width: "811px",
  };
  const doubleStyle = {
    width: "365px",
    background: "#F5F5F5",
  };
  return (
    <Form title="Add Product" close={() => props.close(false)}>
      {/* <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit</p> */}
      <div className="small-input">
        <Input
          icon={user}
          label="Product Name"
          type="text"
          name="name"
          placeholder="Product Name"
          value={product.name}
          handleInput={handleInput}
        />
        <Input
          icon={user}
          label="Product Model"
          type="text"
          name="model"
          placeholder="Product Model"
          value={product.model}
          handleInput={handleInput}
        />
      </div>
      <div className="small-input">
        {/*<Input
          icon={user}
          label="Product Category"
          type="text"
          name="category"
          placeholder="Product Category"
          value={product.category}
          handleInput={handleInput}
        />
        <Input
          icon={user}
          label="Product Subcategory"
          type="text"
          name="subcategory"
          placeholder="Product Subcategory"
          value={product.subcategory}
          handleInput={handleInput}
  />*/}
        <Select
          icon={user}
          label={"Product Category"}
          options={props.categories.map((category) => {
            return {
              name: category.category,
              value: category.category,
            };
          })}
          placeholder={"Select Category"}
          selectedValue={selectedCategory}
          selectValue={(category) => {
            setSelectedCategory(category.name);
          }}
        />
        <Select
          label="Product Subcategory"
          icon={user}
          placeholder={"Product Subcategory"}
          options={getSubcategories(selectedCategory)}
          selectedValue={selectedSubCategory.name}
          selectValue={(subcategory) => {
            setSelectedSubcategory(subcategory);
          }}
        />
      </div>
      <TextArea
        icon={user}
        name="description"
        label="Product Description"
        type="text"
        value={product.description}
        placeholder="Product Description"
        handleInput={handleInput}
      />
      <button onClick={onSubmit}>Next</button>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </Form>
  );
}

export default AddingProduct;
