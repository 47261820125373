import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import "./Login.css";

import UseForm from "../Components/Form/UseForm";
import BigModal from "../Components/Form/BigModal";
import SingleInput from "../Components/Input/SingleInput";
import { authAPIs } from "../api";
import SuccessMessage from "../Components/Success/SuccessMessage";
import SuccessMessageEmail from "../Components/Success/SuccessMessageEmail";
import user from "../Assets/user.svg";
import TopIcon from "../Assets/Green 1.png";

function ForgotPassword() {
  const email = useRef();
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });

  async function forgotPassword(resetEmail) {
    try {
      const raw = await fetch(`${authAPIs.forgetpassword}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(resetEmail),
      });
      let res = await raw.json();
      if (!["authorized", "success"].includes(res.status)) {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  async function onSubmit() {
    const useremail = { email: email.current?.value };
    console.log(JSON.stringify(useremail));
    forgotPassword(useremail)
      .then((response) => {
        message.current = response.message;
        setPasswordError(true);
      })
      .catch((err) => {
        message.current = err.message;
        setPasswordError(true);
      });
  }

  return (
    <UseForm>
      <div className="login">
        <img src={TopIcon} alt="logo" />
        <h2>Login</h2>
        <p className="logintop">
          Enter your email so we can send a password reset link
        </p>
        <SingleInput
          label="Email"
          src={user}
          myref={email}
          type="email"
          placeholder="Your email"
          inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
          inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        />
        <br />

        <button onClick={onSubmit}>Send Link</button>

        {passwordError ? (
          <BigModal
            style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
          >
            <SuccessMessageEmail
              closeSuccess={() => {
                setPasswordError(false);
              }}
              message={message.current}
            />
          </BigModal>
        ) : (
          ""
        )}
      </div>
    </UseForm>
  );
}

export default ForgotPassword;
