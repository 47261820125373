import React, { useEffect, useRef, useState } from "react";
import { TeamProduct } from "../../api";

import ErrorMessage from "../Error/ErrorMessage";
import BigModal from "../Form/BigModal";

import user from "../../Assets/user.svg";
import Form from "../Form/v2/Form/Form";
import Input from "../Form/v2/Input/Input";
import TextArea from "../Form/v2/Input/TextArea";
import Select from "../Form/v2/Input/Select";

function EditProduct(props) {
  const [error, setError] = useState(false);
  const message = useRef();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubcategory] = useState({});
  const [product, setProduct] = useState({
    name: props?.CurrentData?.name || "",
    model: props?.CurrentData?.model || "",
    category: props?.CurrentData?.category || "",
    subcategory: props?.CurrentData?.subcategory || "",
    description: props?.CurrentData?.description || "",
  });

  const handleInput = (e) => {
    product[e.target.name] = e.target.value;
    setProduct({ ...product });
  };

  async function EditProduct(PaymentData) {
    try {
      const raw = await fetch(
        `${TeamProduct.editProducts(props.CurrentData._id)}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            ...PaymentData,
            category: selectedCategory,
            subcategory: selectedSubCategory.name,
          }),
        }
      );
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  function onSubmit() {
    EditProduct(product)
      .then(() => {
        message.current = "Product Updated Successfully!";
        setError(true);
        props.updateProductList({
          ...props.CurrentData,
          ...product,
          category: selectedCategory,
          subcategory: selectedSubCategory.name,
        });
      })
      .catch((err) => {
        if (err.message === "Failed to update products!") {
          err.message = "Product is already up to date!";
        }
        message.current = err.message;
        setError(true);
      });
  }

  const getSubcategories = (selectedCategory) => {
    if (!selectedCategory) return [];
    const category = props.categories.filter(
      (category) => category.category === selectedCategory
    )[0];
    if (!category) return [];

    return category.subcategories.map((subcategory) => {
      return {
        name: subcategory.subcategory,
        value: subcategory._id,
      };
    });
  };

  useEffect(() => {
    if (!props?.CurrentData?.category) return;
    setSelectedCategory(props.CurrentData.category);
    setSelectedSubcategory({
      name: props.CurrentData.subcategory,
      value: props.CurrentData.subcategory,
    });
  }, [props?.CurrentData?.category]);

  return (
    <Form title={"Edit Product"} close={() => props.editData(false)}>
      <div className="small-input">
        <Input
          label="Product Name"
          name="name"
          icon={user}
          placeholder={"Product Name"}
          value={product.name}
          handleInput={handleInput}
          type="text"
        />
        <Input
          label="Product Model"
          name="model"
          icon={user}
          placeholder={"Product Model"}
          value={product.model}
          handleInput={handleInput}
          type="text"
        />
      </div>
      <div className="small-input">
        {/*<Input
          label="Product Category"
          name="category"
          icon={user}
          placeholder={"Product Category"}
          value={product.category}
          handleInput={handleInput}
          type="text"
        />*/}
        <Select
          icon={user}
          label={"Product Category"}
          options={props.categories.map((category) => {
            return {
              name: category.category,
              value: category.category,
            };
          })}
          placeholder={"Select Category"}
          selectedValue={selectedCategory}
          selectValue={(category) => {
            setSelectedCategory(category.name);
          }}
        />
        <Select
          label="Product Subcategory"
          icon={user}
          placeholder={"Product Subcategory"}
          options={getSubcategories(selectedCategory)}
          selectedValue={selectedSubCategory.name}
          selectValue={(subcategory) => {
            setSelectedSubcategory(subcategory);
          }}
        />
        {/*<Input
          label="Product Subcategory"
          name="subcategory"
          icon={user}
          placeholder={"Product Subcategory"}
          value={product.subcategory}
          handleInput={handleInput}
          type="text"
        />*/}
      </div>
      <TextArea
        label="Product Description"
        name="description"
        icon={user}
        placeholder={"Product Description"}
        value={product.description}
        handleInput={handleInput}
        type="text"
      />
      <button onClick={onSubmit}>Save Changes</button>
      {error ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </Form>
  );
}

export default EditProduct;
