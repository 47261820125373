//const origin = "http://127.0.0.1:5000";
const origin = "https://api.thegreenmanual.com";

export const authAPIs = {
  registerClient: `${origin}/api/v1/auth/register-client`,
  createCompany: `${origin}/api/v1/companies`,
  ProviderLogin: `${origin}/api/v1/auth/provider-login`,
  validateToken: `${origin}/api/v1/auth/validate`,
  forgetpassword: `${origin}/api/v1/auth/forget-password`,
  getS3Hash: `${origin}/api/v1/auth/s3-hash`,
};

export const TeamTable = {
  SubClients: (sortBy) => `${origin}/api/v1/subclients?sort=${sortBy}`,
  inviteSubclient: `${origin}/api/v1/subclients/invite`,
  deleteSubClients: (id) => `${origin}/api/v1/subclients/${id}`,
  editSubClients: (id) => `${origin}/api/v1/subclients/${id}`,
};

export const TeamProduct = {
  GetProducts: (sortBy) =>
    `${origin}/api/v1/products?fields=comment,blocked,name,model,active,subcategory,category,description,assignees,createdAt,sections&sort=${sortBy}`,
  addProducts: `${origin}/api/v1/products`,
  deleteProducts: (id) => `${origin}/api/v1/products/${id}`,
  editProducts: (id) => `${origin}/api/v1/products/${id}`,
  countTotalProducts: `${origin}/api/v1/products/products-count`,
  countUpdatedProducts: `${origin}/api/v1/products/products-count?active=true`,
  addAssignee: (id) => `${origin}/api/v1/products/${id}/assignees`,
  deleteAssignee: (id1, id2) =>
    `${origin}/api/v1/products/${id1}/assignees/${id2}`,
  saveSections: (productId) =>
    `${origin}/api/v1/products/${productId}/sections`,
};

export const ProfileAPIs = {
  profile: `${origin}/api/v1/auth/fetch-profile`,
  company: (userId) => `${origin}/api/v1/companies/${userId}`,
  updateProfile: `${origin}/api/v1/auth/update-profile`,
};

export const SectionAPIs = {
  deleteSection: (id) => `${origin}/api/v1/sections/${id}`,
  addSection: `${origin}/api/v1/sections`,
  getSections: (id) => `${origin}/api/v1/products/${id}`,
  updateSectionsContent: (id) => `${origin}/api/v1/sections/${id}/add-content`,
};

export const SubscriptionAPIs = {
  SubscriptionCard: `${origin}/api/v1/plans`,
  BuyPlan: (planId, qty) => `${origin}/api/v1/plans/${planId}/buy?qty=${qty}`,
  createTrail: `${origin}/api/v1/subscriptions/create-trial`,
  activeSubscriptions: `${origin}/api/v1/subscriptions/active-subscriptions`,
  subscriptions: `${origin}/api/v1/subscriptions`,
  cancelSubscription: (subscriptionId) =>
    `${origin}/api/v1/subscriptions/${subscriptionId}/cancel`,
};

export const SubscriptionAPIsv2 = {
  subscriptions: `${origin}/api/v2/subscriptions`,
  buysubscriptions: `${origin}/api/v2/subscriptions`,
  cancelSubscription: (subscriptionId) =>
    `${origin}/api/v2/subscriptions/${subscriptionId}`,
  fetchPlans: `${origin}/api/v2/plans?active=true`,
};

export const PaymentAPIs = {
  CardBilliing: `${origin}/api/v1/billingaccounts/payment-methods`,
  CardBilliingV2: `${origin}/api/v2/billingaccounts/payment-methods`,
};

export const PaymentAPIsV2 = {
  deleteCard: (id) => `${origin}/api/v2/billingaccounts/payment-methods/${id}`,
  setDefault: (id) => `${origin}/api/v2/billingaccounts/payment-methods/${id}`,
};

export const CategoryAPIs = {
  fetchCategories:`${origin}/api/v1/categories`
}