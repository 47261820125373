import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import "./Payment.css";

import SingleInput from "../../Input/SingleInputv2";
import DoubleInput from "../../Input/DoubleInputv2";
import BigModal from "../../Form/BigModal";
import ErrorMessage from "../../Error/ErrorMessage";
import { PaymentAPIs } from "../../../api";

import user from "../../../Assets/user.svg";
import emailicon from "../../../Assets/email.png";

function Payment(props) {
  const nameoncard = useRef("");
  const cardnumber = useRef("");
  const expiryDate = useRef("");
  const cvv = useRef("");
  const message = useRef("");
  const [passwordError, setPasswordError] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });
  const navigate = useNavigate();
  async function PostPayment(paymentData) {
    try {
      const raw = await fetch(`${PaymentAPIs.CardBilliingV2}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify(paymentData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  function onSubmit() {
    const trial = JSON.parse(window.localStorage.getItem("trial") || "{}");
    console.log(trial);
    const paymentMethod = {
      paymentMethod: {
        number: cardnumber.current.replace(/[" "]/g, ""),
        exp_month: `${expiryDate.current.split(" / ")[0]}`,
        exp_year: `${expiryDate.current.split(" / ")[1]}`,
        cvc: cvv.current,
      },
      type: "card",
      payType: "new_default_method",
      trial: true,
      product: trial.priceId,
      qty: trial.qty || 1,
    };

    PostPayment(paymentMethod)
      .then((response) => {
        console.log(response);
        if (response.status === "success") {
          props.GivePaymentData({ current: 5 });
        } else {
          // navigate('/signup');
        }
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      });
    // props.GivePaymentData(paymentMethod, {'current':5});
  }
  return (
    <div className="payment">
      <h2>Payment</h2>
      <SingleInput
        src={user}
        label="Name On Card"
        myref={nameoncard}
        type="text"
        placeholder="Name on Card"
        inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        handleInput={(e) => {
          nameoncard.current = e.target.value;
        }}
      />
      <SingleInput
        src={user}
        label="Card Number"
        myref={cardnumber}
        type="text"
        placeholder="Card Number"
        inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        handleInput={(e) => {
          if (cardnumber.current.length === 19 && e.target.value.length > 19) {
            e.target.value = cardnumber.current;
            return;
          }
          if (
            (cardnumber.current.length < 4 ||
              (cardnumber.current.length < 9 &&
                cardnumber.current.length !== 5) ||
              (cardnumber.current.length < 14 &&
                cardnumber.current.length !== 10 &&
                cardnumber.current.length !== 5)) &&
            [4, 9, 14].includes(e.target.value.length)
          ) {
            e.target.value = e.target.value + " ";
          }
          cardnumber.current = e.target.value;
        }}
      />
      {!isMobile && (
        <DoubleInput
          src1={emailicon}
          label1="Expiry Date"
          myref1={expiryDate}
          type1="text"
          placeholder1="eg. 12 / 24"
          src2={emailicon}
          label2="CVV"
          myref2={cvv}
          type2="text"
          placeholder2="CVV"
          handleExpiryInput={(e) => {
            if (expiryDate.current.length === 7 && e.target.value.length > 7) {
              e.target.value = expiryDate.current;
              return;
            }

            if (
              expiryDate.current.length < 2 &&
              e.target.value.length == 2 &&
              e.target.value.length <= 3
            ) {
              e.target.value = `${e.target.value} / `;
            }
            expiryDate.current = e.target.value;
          }}
          handleCVVInput={(e) => {
            if (cvv.current.length === 3 && e.target.value.length > 3) {
              e.target.value = cvv.current;
              return;
            }
            cvv.current = e.target.value;
          }}
        />
      )}
      {isMobile && (
        <SingleInput
          src={emailicon}
          label="Expiry Date"
          myref={expiryDate}
          type="text"
          placeholder="eg. 12 / 24"
          inputStyle={{ width: "calc(100% - 60px)" }}
          inputLabelStyle={{ width: "calc(100% - 60px)" }}
          handleInput={(e) => {
            if (expiryDate.current.length === 7 && e.target.value.length > 7) {
              e.target.value = expiryDate.current;
              return;
            }

            if (
              expiryDate.current.length < 2 &&
              e.target.value.length == 2 &&
              e.target.value.length <= 3
            ) {
              e.target.value = `${e.target.value} / `;
            }
            expiryDate.current = e.target.value;
          }}
        />
      )}
      {isMobile && (
        <SingleInput
          src={emailicon}
          label="CVV"
          myref={cvv}
          type="text"
          placeholder="CVV"
          inputStyle={{ width: "calc(100% - 60px)" }}
          inputLabelStyle={{ width: "calc(100% - 60px)" }}
          handleInput={(e) => {
            if (cvv.current.length === 3 && e.target.value.length > 3) {
              e.target.value = cvv.current;
              return;
            }
            cvv.current = e.target.value;
          }}
        />
      )}
      <div className="next" onClick={onSubmit}>
        &#10095;
      </div>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default Payment;
