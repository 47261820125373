import React, { useState, useEffect, useRef } from "react";
import "./PaymentSubscriptionCard.css";
import { SubscriptionAPIsv2 } from "../../api";
import BigModal from "../Form/BigModal";
import SuccessMessage from "../Success/SuccessMessage";

function PaymentSubscriptionCard() {
  const [subData, setSubData] = useState([]);
  const [alert, setAlert] = useState(false);
  const message = useRef();
  async function GetSub() {
    try {
      const raw = await fetch(
        `${SubscriptionAPIsv2.subscriptions}?status=all`,
        {
          method: "GET",
          headers: { token: localStorage.getItem("token") },
        }
      );
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function cancelSub(subId) {
    try {
      const raw = await fetch(
        `${SubscriptionAPIsv2.cancelSubscription(subId)}`,
        {
          method: "DELETE",
          headers: { token: localStorage.getItem("token") },
        }
      );
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  useEffect(() => {
    GetSub().then((response) => {
      setSubData(response?.data?.subscriptions);
    });
  }, []);

  function onSubmit(subId) {
    cancelSub(subId)
      .then((response) => {
        message.current = response.message;
        setAlert(true);
        //update status
        const subIndex = subData.findIndex((sub) => `${sub.id}` === subId);
        subData[subIndex].status = "cancelled";
        setSubData([...subData]);
      })
      .catch((err) => {
        message.current = err.message;
        setAlert(true);
      });
  }

  function giveDate(date) {
    var d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}-${month}-${year}`;
  }

  const getStatus = (data) => {
    if (data.canceled_at && data.status !== "canceled") {
      data.tempstatus = giveDate(data.canceled_at * 1000);
    }
    return {
      el:
        ["active","trialing"].includes(data?.status) && !data.canceled_at ? (
          <button onClick={() => onSubmit(data?.id)}>Cancel</button>
        ) : (
          (data.tempstatus || "")
        ),
    };
  };

  return (
    <div className="payment-subscription-card">
      <h2>Payment Subscription</h2>
      <p>The following table shows your subscription</p>
     
      <table>
        <thead>
          <tr>
            <th style={{ width: "53px" }}>
              <label htmlFor="employee">S.No</label>
            </th>
            <th style={{ width: "156px" }}>Products</th>
            <th style={{ width: "88px" }}>Price</th>
            <th style={{ width: "150px" }}>Plan</th>
            <th style={{ width: "150px" }}>Added Date</th>
            <th style={{ width: "150px" }}>End Date</th>
            <th style={{ width: "118px" }}>Status</th>
            <th style={{ width: "118px" }}>Cancel On</th>
          </tr>
        </thead>
        <tbody>
          {subData.map((data, id) => (
            <tr key={id}>
              <td style={{ width: "53px" }}>
                <label htmlFor="employee">{id++ + 1}</label>
              </td>
              <td style={{ width: "156px" }}>
                {data?.usedProduct}/{data?.plan[0]?.quantity}
              </td>
              <td style={{ width: "88px" }}>
                {(data?.plan[0]?.price * data.plan[0].quantity) / 100}$
              </td>
              <td style={{ width: "150px" }}>{data?.plan[0]?.product}</td>
              <td style={{ width: "150px" }}>
                {giveDate(data?.current_period_start * 1000)}
              </td>
              <td style={{ width: "150px" }}>
                {giveDate(data?.current_period_end * 1000)}
              </td>
              <td
                style={{ width: "118px", textTransform: "capitalize" }}
              >
                {data.status}
              </td>
              <td
                className={getStatus(data).status}
                style={{ width: "118px", textTransform: "capitalize" }}
              >
                {getStatus(data).el}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {alert ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <SuccessMessage
            closeSuccess={() => {
              setAlert(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default PaymentSubscriptionCard;
