import React from 'react'
import BackgroundImage from '../../Assets/Background.png';
import './Background.css';
function Background() {
  return (
    <div className='background'>
        <img src = {BackgroundImage}/>
    </div>
  )
}

export default Background;