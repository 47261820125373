import "./form.css";
function Form(props) {
  return (
    <div className="form-container">
      <div className="form-container-head">
        <h2>{props.title}</h2>
        <div className="close-container">
          <span onClick={props.close} className="fa fa-times"></span>
        </div>
      </div>
      <div className="form-container-body">{props.children}</div>
    </div>
  );
}

export default Form;
