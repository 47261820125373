// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCRZQrqNqnXq2FfbI5hROeolX5UAILqDHQ",
  authDomain:"green-manual-mobile-app.firebaseapp.com",
  projectId: "green-manual-mobile-app",
  storageBucket: "green-manual-mobile-app.appspot.com",
  messagingSenderId: "638582982118",
  appId: "1:638582982118:web:f5eb5ad233e814a2760227",
  measurementId: "G-5W3CSG4KJK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;

{/*authDomain: "green-manual-mobile-app.firebaseapp.com",*/}
