import React from "react";
import "./PaymentSuccessful.css";

import tick from "../../../Assets/tick.png";

function PaymentSuccessful(props) {
  const subscriptionId = props?.data?.subscriptionId;
  console.log(subscriptionId);
  function onSubmit() {
    props.GiveData(subscriptionId);
  }
  return (
    <div className="payment-success">
      <div className="main-container">
        <h2>{props.title}</h2>
        {/* <p>{props.detail}</p> */}
        <div>
          <img src={tick} />
        </div>
        <button onClick={onSubmit}>Next</button>
      </div>
    </div>
  );
}

export default PaymentSuccessful;
