import './App.css';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './Components/Auth/Auth';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import TeamTable from './Pages/TeamTable';
import TeamProduct from './Pages/TeamProduct';
import Profile from './Pages/Profile';
import AddProduct from './Components/TeamProduct/AddProduct';
import EditProjectPage from './Pages/EditProjectPage';
import PaymentSubscription from './Pages/PaymentSubscription';
import Testing from './Components/EditProject/FroalaEditor';
import ForgotPassword from './Pages/ForgotPassword';

function App() {

  return (
    <div className="App">
      <AuthProvider>
      <Routes base>
        <Route path='/' element={<Login/>}/>
        <Route path='/forgot-password' element={<ForgotPassword/>}/>
        <Route path='/signup' element={<Signup/>}/>
        <Route path='/teamtable' element={<TeamTable/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/teamproduct' element={<TeamProduct/>}/>
        <Route path='/addproduct' element={<AddProduct/>}/>
        <Route path='/teamproduct/:id' element={<EditProjectPage/>}/>
        <Route path='/paymentsubscription' element={<PaymentSubscription/>}/>
      </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
