import React, { useEffect, useState } from "react";

import UseForm from "../Components/Form/UseForm";
import SignupForm from "../Components/Signup/SignupForm";
import SignupComplete from "../Components/Signup/SignupComplete";
import SignupStep from "../Components/Signup/SignupStep";
import { useAuth } from "../Components/Auth/Auth";

function Signup() {
  const [isStepComplete, SetisStepComplete] = useState(false);
  const [isSignupComplete, SetisSignupComplete] = useState(false);
  const [current, setCurrent] = useState(0);
  const auth = useAuth();

  function SignupFormSubmit(value) {
    SetisSignupComplete(value);
  }
  function StepComplete(value) {
    SetisStepComplete(value);
  }

  function giveCurrent() {
    const tokenExist = localStorage.getItem("token");
    const companyIdExist = auth?.user?.companyId;
    const paytmentIdExist = auth?.user?.billingAccountEnabled;

    if (tokenExist) {
      if (
        companyIdExist === undefined ||
        companyIdExist === null ||
        !companyIdExist
      ) {
        console.log("open company page!");
        return 2;
      }
      if (tokenExist && companyIdExist) {
        console.log("open billing page!");
        return 3;
      }
      if (companyIdExist && !paytmentIdExist) {
        return 4;
      }
    } else {
      return 1;
    }
  }

  useEffect(() => {
    //if (!auth.user) return;
    setCurrent(giveCurrent());
  }, [auth.user]);

  return (
    <UseForm>
      {isStepComplete ? (
        <SignupComplete />
      ) : (
        <SignupStep
          isStepComplete={StepComplete}
          current={current}
          setCurrent={setCurrent}
        />
      )}
    </UseForm>
  );
}

export default Signup;

// (isSignupComplete?
//   <SignupComplete/>
//   :<SignupForm isSignupComplete={SignupFormSubmit}/>)
