import React, { useState } from "react";
import "./PasswordInput.css";
import OpenEye from "../../Assets/view.png";
import CloseEye from "../../Assets/hide.png";

function PasswordInput(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <div className="password-input">
      <div className="icon-box" style={props?.inputLabelStyle}>
        <img src={props.src} style={props?.iconStyle} />
        <p>{props.label}</p>
      </div>
      <div className="input">
        <input
          style={props?.inputStyle}
          ref={props.myref}
          type={passwordShown ? "text" : "password"}
          placeholder={props.placeholder}
          defaultValue={props.value}
          readOnly={props.readOnly}
        />
        {passwordShown ? (
          <img src={OpenEye} onClick={togglePasswordVisiblity} />
        ) : (
          <img src={CloseEye} onClick={togglePasswordVisiblity} />
        )}
      </div>
    </div>
  );
}

export default PasswordInput;
