import { React, useState, useRef } from "react";
import "./SubscriptionCard.css";
import check from "../../../Assets/check.svg";
import add from "../../../Assets/add4.png";
import minus from "../../../Assets/minus.png";
function SubscriptionCard(props) {
  const SubscriptionData = props?.data;
  const [quantity, setQuantity] = useState(1);

  function onSubmit() {
    const planData = {
      priceId: SubscriptionData.priceId,
      qty: quantity,
    };
    props.GiveData(planData);
  }

  const details = {
    monthly: {
      description:
        "Start here if you want to experiment with Green Manual but not completely sure of a long term commitment.",
      features: [
        "First 30 days are free",
        "Cancel anytime with no commitment",
        "Get support to create the right post sale offerings for your customers",
      ],
    },
    yearly: {
      description:
        "Continue here if you want to set your customers up for a great post sale experience in the long run.",
      features: [
        "First 30 days are free",
        "Save more on long term engagement",
        "Get support to create the right post sale offerings for your customers",
      ],
    },
  };

  const getPlan = (renewal) => {
    return {
      day: "daily",
      month: "monthly",
      year: "yearly",
    }[renewal];
  };

  return (
    <div className="SubscriptionCard" style={props.style}>
      <p style={props?.topTypeStyle} className="type">
        {SubscriptionData?.plan}
      </p>
      <p style={props?.topHeadingStyle1} className="price">
        <span className="dollar">$</span>
        {SubscriptionData?.amount / 100}
        <span className="per">/{getPlan(SubscriptionData.renewInterval)}</span>
      </p>
      <p style={props?.topHeadingStyle2} className="details">
        {details[getPlan(SubscriptionData.renewInterval)]?.description}
      </p>
      <ul>
        {details[getPlan(SubscriptionData.renewInterval)]?.features.map(
          (data) => {
            return (
              <li key={data._id}>
                <div>
                  <img src={check} />
                </div>
                <p>{data}</p>
              </li>
            );
          }
        )}
      </ul>
      <div className="quantity">
        <div
          className="minus"
          onClick={() => {
            if (quantity > 1) setQuantity(quantity - 1);
          }}
        >
          <img src={minus} />
        </div>
        <div className="number">{quantity}</div>
        <div
          className="plus"
          onClick={() => {
            setQuantity(quantity + 1);
          }}
        >
          <img src={add} />
        </div>
      </div>
      <div className="btns" style={props?.allButtonstyles}>
        <button style={props?.buttonstyle} onClick={onSubmit}>
          Buy Now
        </button>
      </div>
    </div>
  );
}

export default SubscriptionCard;
