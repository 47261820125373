import React, { useRef, useState, useEffect } from "react";
import "./ProfileCard.css";
import { useMediaQuery } from "react-responsive";
import SingleInput from "../Input/SingleInput";
import DoubleInput from "../Input/DoubleInput";
import Modal from "../Form/v2/Modal/Modal";
import BigModal from "../Form/BigModal";
import PaymentMethod from "./PaymentMethod";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Success/SuccessMessage";

import { ProfileAPIs, PaymentAPIs, PaymentAPIsV2 } from "../../api";

import user from "../../Assets/user.svg";
import emailicon from "../../Assets/email.png";
import greenbutton from "../../Assets/greenbutton.png";
import visa from "../../Assets/Visa.png";
import pencil from "../../Assets/pencil3.png";
import save from "../../Assets/save.svg";
import add from "../../Assets/add.png";
import deleteImg from "../../Assets/delete.png";

function ProfileCard(props) {
  const name = useRef();
  const email = useRef();
  const message = useRef();
  const designation = useRef();
  const address1 = useRef();
  const address2 = useRef();
  const state = useRef();
  const city = useRef();
  const postalcode = useRef();
  const companyname = useRef();
  const companywebsite = useRef();
  const language = useRef();
  const card = useRef();
  const [profileData, setProfileData] = useState();
  const [companyData, setCompanyData] = useState();
  const [paymentData, setPaymentData] = useState();
  const [getMethod, SetGetMethod] = useState(false);
  const [edit1, setEdit1] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const [compid, setCompid] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const userData = props?.data?.user;
  const role = userData?.role;

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  async function GetProfileData() {
    try {
      const raw = await fetch(`${ProfileAPIs.profile}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function GetPaymentData() {
    try {
      const raw = await fetch(`${PaymentAPIs.CardBilliingV2}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  async function GetCompanyData(Id) {
    try {
      const raw = await fetch(`${ProfileAPIs.company(Id)}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    GetProfileData().then((response) => {
      console.log(response?.data?.user);
      setProfileData(response?.data?.user);
      setCompid(response?.data?.user?.companyId);
      GetCompanyData(response?.data?.user?.companyId).then((response) => {
        console.log(response?.data?.company);
        setCompanyData(response?.data?.company);
      });
      GetPaymentData().then((response) => {
        console.log(response.data);
        setPaymentData(response.data);
      });
    });
  }, []);
  const inputStyle = {
    background: "rgba(111, 191, 157, 0.12)",
    width: "90%",
    minWidth: "240px",
  };

  const doubleStyle = {
    background: "rgba(111, 191, 157, 0.12)",
    width: "165px",
    marginLeft:"0 !important"
  };
  const imgStyle = isMobile
    ? {
        float: "right",
        position: "relative",
        right: "25px",
        bottom: "45px",
        cursor: "pointer",
        width: "20px",
      }
    : {
        float: "right",
        position: "relative",
        right: "130px",
        bottom: "45px",
        cursor: "pointer",
        width: "20px",
      };

  // console.log(profileData)
  async function updateProfile() {
    const ProfileData = {
      name: name.current.value,
      designation: designation.current.value,
      language: language.current.value,
    };
    try {
      const raw = await fetch(`${ProfileAPIs.updateProfile}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify(ProfileData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  function SaveProfile() {
    updateProfile()
      .then((response) => {
        console.log(response);
        setEdit1(true);
      })
      .catch((err) => {
        console.error(err);
        setEdit1(true);
      });
  }
  async function updateCompany() {
    const CompanyData = {
      name: companyname.current.value,
      link: companywebsite.current.value,
      "addresses.address1": address1.current.value,
      "addresses.address2": address2.current.value,
      state: state.current.value,
      city: city.current.value,
      postalCode: postalcode.current.value,
    };
    try {
      const raw = await fetch(`${ProfileAPIs.company(compid)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify(CompanyData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  function SaveCompany() {
    updateCompany()
      .then((response) => {
        console.log(response);
        message.current = "Updated Successfully!";
        setSuccess(true);
        setEdit2(true);
      })
      .catch((err) => {
        message.current = err.message;
        setError(true);
        setEdit2(true);
      });
  }

  async function deletePaymentMethod(id) {
    try {
      const raw = await fetch(`${PaymentAPIsV2.deleteCard(id)}`, {
        method: "DELETE",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function selectDefaultMethod(id) {
    try {
      const raw = await fetch(`${PaymentAPIsV2.deleteCard(id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  return (
    <div className="profile">
      <h2>Profile</h2>
      <p>Edit Your profile here.</p>
      <div className="profile-card">
        <div className="profile-details">
          <h3>Profile Details</h3>
          {edit1 ? (
            <img
              src={pencil}
              style={imgStyle}
              onClick={() => setEdit1(false)}
            />
          ) : (
            <img src={save} style={imgStyle} onClick={SaveProfile} />
          )}
          <div className="details-input">
            <div className="left-details">
              <SingleInput
                inputStyle={inputStyle}
                src={user}
                label="Name"
                myref={name}
                type="text"
                placeholder="Enter name"
                value={profileData?.name}
                readOnly={edit1}
              />
              <SingleInput
                inputStyle={inputStyle}
                src={emailicon}
                label="Email"
                myref={email}
                type="email"
                placeholder="Enter Email"
                value={profileData?.email}
                readOnly={edit1}
              />
            </div>
            <div className="right-details">
              <SingleInput
                inputStyle={inputStyle}
                src={emailicon}
                label="Designation"
                myref={designation}
                type="text"
                placeholder="Designation"
                value={profileData?.designation}
                readOnly={edit1}
              />
            </div>
          </div>
        </div>
        {role === "client" && (
          <div className="company-details">
            <h3>Company Details</h3>
            {edit2 ? (
              <img
                src={pencil}
                style={imgStyle}
                onClick={() => setEdit2(false)}
              />
            ) : (
              <img src={save} style={imgStyle} onClick={SaveCompany} />
            )}
            <div className="details-input">
              <div className="left-details">
                <SingleInput
                  inputStyle={inputStyle}
                  src={user}
                  label=" Company Name"
                  myref={companyname}
                  readOnly={edit2}
                  type="text"
                  placeholder="Enter Company Name"
                  value={companyData?.name}
                />
                <SingleInput
                  inputStyle={inputStyle}
                  src={user}
                  label="Address Line 1"
                  myref={address1}
                  type="text"
                  placeholder="Enter Address"
                  value={companyData?.addresses?.address1}
                  readOnly={edit2}
                />
                <DoubleInput
                  src1={emailicon}
                  label1="State"
                  myref1={state}
                  type1="text"
                  placeholder1="Enter State"
                  value1={companyData?.state}
                  readOnly1={edit2}
                  src2={emailicon}
                  label2="City"
                  myref2={city}
                  type2="text"
                  placeholder2="Enter City"
                  value2={companyData?.city}
                  readOnly2={edit2}
                  doubleStyle={doubleStyle}
                />
              </div>
              <div className="right-details">
                <SingleInput
                  inputStyle={inputStyle}
                  src={user}
                  label="Company Website"
                  myref={companywebsite}
                  readOnly={edit2}
                  type="text"
                  placeholder="Company website"
                  value={companyData?.link}
                />
                <SingleInput
                  inputStyle={inputStyle}
                  src={user}
                  label="Address Line 2"
                  myref={address2}
                  readOnly={edit2}
                  type="text"
                  placeholder="Enter Address"
                  value={companyData?.addresses?.address2}
                />
                <SingleInput
                  inputStyle={inputStyle}
                  src={user}
                  label="Zip Code"
                  myref={postalcode}
                  readOnly={edit2}
                  type="text"
                  placeholder="Enter Zip Code"
                  value={companyData?.postalCode}
                />
              </div>
            </div>
          </div>
        )}

        {role === "client" && (
          <div className="payment-method">
            {/*<div className="payment-method-header">
              <h3>Payment Method</h3>
              <div className="add-btn">
                <img className="add" src={add} />
                <button
                  onClick={() => {
                    SetGetMethod(true);
                  }}
                >
                  Add
                </button>
              </div>
                </div>*/}
            <h3>Payment Method</h3>

            <img
              src={add}
              style={{
                ...imgStyle,
                backgroundColor: "#7ACCA9",
                padding: "5px",
                borderRadius: "5px",
              }}
              onClick={() => {
                SetGetMethod(true);
              }}
            />
            {paymentData ? (
              paymentData?.paymentMethods.map((data, index) => (
                <div
                  className="details"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    selectDefaultMethod(data.id)
                      .then(() => {
                        setSuccess(true);
                        message.current =
                          "Method set to default, Please wait fetching updates";
                        GetPaymentData().then((response) => {
                          console.log(response.data);
                          setPaymentData(response.data);
                          setSuccess(false);
                        });
                      })
                      .catch((err) => {
                        message.current = err.message;
                        setError(true);
                      });
                  }}
                >
                  {/* {console.log(data.card)} */}
                  {paymentData?.defaultMethod === data?.id ? (
                    <img className="green" src={greenbutton} />
                  ) : (
                    <></>
                  )}
                  <SingleInput
                    inputStyle={inputStyle}
                    inputLabelStyle={{
                      position: "relative",
                      right: "15px",
                      cursor: "pointer",
                      minWidth: "140px",
                    }}
                    src={user}
                    label="Card"
                    myref={card}
                    type="text"
                    placeholder={"XXXX-XXXX-XXXX-XXXX"}
                    value={`XXXX-XXXX-XXXX-${data?.card?.last4}`}
                    readOnly={true}
                    className="card-input"
                  />
                  {data?.card?.brand === "visa" ? (
                    <>
                      <img className="visa" src={visa} />
                    </>
                  ) : (
                    <>
                      <img className="visa" src={visa} />
                    </>
                  )}
                  <img
                    className="delete"
                    src={deleteImg}
                    style={{
                      margin: "auto 10px 30px 0",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      deletePaymentMethod(data.id)
                        .then(() => {
                          window.location.reload();
                        })
                        .catch((err) => {
                          message.current = err.message;
                          setError(true);
                        });
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="details">
                <img className="green" src={greenbutton} />
                <SingleInput
                  inputStyle={inputStyle}
                  inputLabelStyle={{ position: "relative", right: "15px" }}
                  src={user}
                  label="Card"
                  myref={card}
                  type="text"
                  placeholder="xxxx-xxxx-xxxx-2341"
                />
                <img className="visa" src={visa} />
                <img className="add" src={add} />
                <button
                  onClick={() => {
                    SetGetMethod(true);
                  }}
                >
                  Add
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {getMethod ? (
        <Modal>
          <PaymentMethod
            getMethodData={() => {
              SetGetMethod(false);
            }}
            newCard={(card) => {
              paymentData.paymentMethods.push(card);
              setPaymentData(paymentData);
            }}
          />
        </Modal>
      ) : (
        ""
      )}
      {error ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}

      {success ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <SuccessMessage
            closeError={() => {
              setSuccess(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}
export default ProfileCard;
