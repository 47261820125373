import React, { useState } from "react";

import AddPlan from "./AddProduct/AddPlan";
import PaymentProduct from "./AddProduct/PaymentProduct";
import PaymentSuccessful from "./AddProduct/PaymentSuccessful";
import AddingProduct from "./AddProduct/AddingProduct";
import GetSubscriptions from "./AddProduct/GetSubscriptions";

function AddProduct(props) {
  const [current, setCurrent] = useState(1);
  const [stepData, setStepData] = useState("");
  function OnNext(value) {
    if (current === 6) {
      props.userData(false);
    } else if (value.current === 5) {
      setCurrent(5);
      setStepData(value);
    } else {
      setCurrent(current + 1);
      setStepData(value);
    }
  }
  const title1 = "Payment Successful";
  const detail1 =
    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit";
  const title2 = "Product Added Successfully";
  const detail2 =
    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit";
  return (
    <div style={{width:"100%", margin:"auto"}}>
      {current === 1 && (
        <GetSubscriptions GiveData={OnNext} close={props.userData} />
      )}
      {current === 2 && <AddPlan GiveData={OnNext} close={props.userData} />}
      {current === 3 && (
        <PaymentProduct
          GiveData={OnNext}
          close={props.userData}
          data={stepData}
        />
      )}
      {current === 4 && (
        <PaymentSuccessful
          title={title1}
          detail={detail1}
          GiveData={OnNext}
          data={stepData}
          close={props.userData}
        />
      )}
      {current === 5 && (
        <AddingProduct
          GiveData={OnNext}
          categories={props.categories}
          close={props.userData}
          data={stepData}
        />
      )}
      {current === 6 && (
        <PaymentSuccessful
          title={title2}
          detail={detail2}
          GiveData={OnNext}
          data={stepData}
          close={props.userData}
        />
      )}
    </div>
  );
}

export default AddProduct;
