import React from "react";
import "./TripleInput.css";
function TripleInput(props) {
  return (
    <div className="triple-input" style={props?.bigDivStyle}>
      <div className="triple-input-left" style={props?.tripleDivStyle}>
        <div>
          <img src={props.src1} />
          <p>{props.label1}</p>
        </div>
        <input
          style={props?.tripleStyle}
          ref={props.myref1}
          type={props.type1}
          placeholder={props.placeholder1}
          defaultValue={props.value1}
          readOnly={props.readOnly1}
        />
      </div>
      <div className="triple-input-middle" style={props?.tripleDivStyle}>
        <div>
          <img src={props.src2} />
          <p>{props.label2}</p>
        </div>
        <input
          style={props.tripleStyle}
          ref={props.myref2}
          type={props.type2}
          placeholder={props.placeholder2}
          defaultValue={props.value2}
          readOnly={props.readOnly2}
        />
      </div>
      <div className="triple-input-right" style={props?.tripleDivStyle}>
        <div>
          <img src={props.src3} />
          <p>{props.label3}</p>
        </div>
        <input
          style={props.tripleStyle}
          ref={props.myref3}
          type={props.type3}
          placeholder={props.placeholder3}
          defaultValue={props.value3}
          readOnly={props.readOnly3}
        />
      </div>
    </div>
  );
}

export default TripleInput;
