import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./Login.css";

import UseForm from "../Components/Form/UseForm";
import BigModal from "../Components/Form/BigModal";
import SingleInput from "../Components/Input/SingleInput";
import PasswordInput from "../Components/Input/PasswordInput";
import ErrorMessage from "../Components/Error/ErrorMessage";
import SuccessMessageEmail from "../Components/Success/SuccessMessageEmail";
import { authAPIs } from "../api";

import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import app from "../Components/Login/firebase";

import user from "../Assets/user.svg";
import TopIcon from "../Assets/Green 1.png";
import google from "../Assets/google.png";

function Login() {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const password = useRef();
  const email = useRef();
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });

  async function ProviderLogin(firebasetoken) {
    try {
      const raw = await fetch(`${authAPIs.ProviderLogin}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "firebase-token": firebasetoken,
        },
        body: JSON.stringify({ provider: "google", level: 2, role: "client", platform:"website" }),
      });
      let res = await raw.json();
      if (!["authorized", "success"].includes(res.status)) {
        console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  async function onSubmit() {
    const useremail = email.current?.value;
    const userpassword = password.current?.value;
    try {
      const res = await signInWithEmailAndPassword(
        auth,
        useremail,
        userpassword
      );
      await handleLogin(res.user.accessToken);
    } catch (error) {
      console.log(error);
      message.current = "Incorrect Email or Password Please Try Again";
      setPasswordError(true);
    }
  }
  async function handleLogin(token) {
    ProviderLogin(token)
      .then((response) => {
        if (response.status === "authorized") {
          const companyid = response?.data?.companyId;
          const isSubscription = response?.data?.billingAccountEnabled;
          const role = response?.data?.role;
          localStorage.setItem("token", response.token);

          if (isSubscription === true && companyid && role === "client") {
            navigate("/teamtable");
          } else if (role === "subclient") {
            navigate("/teamproduct");
          } else if (!companyid && role === "client") {
            navigate("/signup");
          } else if (isSubscription === false && role === "client") {
            navigate("/signup");
          } else {
            navigate("/");
          }
        } else {
          console.log(response.message);
          if (response.status === "success") {
            message.current = response.message;
            setEmailVerify(true);
          } else {
            message.current = response.message;
            setPasswordError(true);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function GoogleLogin() {
    try {
      const auth = getAuth(app);
      const googleProvider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, googleProvider);
      await handleLogin(res.user.accessToken);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <UseForm>
      <div className="login">
        <img src={TopIcon} alt="logo" />
        <h2>Login</h2>
        <p className="logintop">Login with your created email and password</p>
        <div className="input-box">
          <SingleInput
            label="Email"
            src={user}
            myref={email}
            type="email"
            placeholder="Your email"
            inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
            inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
          />
          <PasswordInput
            label="Password"
            src={user}
            myref={password}
            type="password"
            placeholder="Your password"
            inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
            inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
          />
          <p
            className="forgot"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot Password ?
          </p>
        </div>

        {/* <br/> */}
        <button onClick={onSubmit}>Login</button>
        <div className="google-login" onClick={GoogleLogin}>
          <img src={google} alt="google" />
          <p>Login By Google</p>
        </div>
        <p className="new">
          New Customer ?
          <span
            onClick={() => {
              navigate("/signup");
            }}
          >
            {" "}
            Register Here
          </span>
        </p>
        {passwordError ? (
          <BigModal
            style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
          >
            <ErrorMessage
              closeError={() => {
                setPasswordError(false);
              }}
              message={message.current}
            />
          </BigModal>
        ) : (
          ""
        )}
        {emailVerify ? (
          <BigModal
            style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
          >
            <SuccessMessageEmail
              closeSuccess={() => {
                setEmailVerify(false);
              }}
              message={message.current}
            />
          </BigModal>
        ) : (
          ""
        )}
      </div>
    </UseForm>
  );
}

export default Login;
