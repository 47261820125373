import "./input.css";

function Input(props) {
  return (
    <div className="modal-form-input">
      <div className="label">
        <img src={props.icon} alt="user" />
        <label>{props.label}</label>
      </div>
      <input
        className="main-style"
        name={props.name}
        onChange={props.handleInput}
        type={props.type}
        value={props.value}
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default Input;
