import "./input.css";

function TextArea(props) {
  return (
    <div className="modal-form-input">
      <div className="label">
        <img src={props.icon} alt="user" />
        <label>{props.label}</label>
      </div>
      <textarea
        className="main-style"
        type={props.type}
        name={props.name}
        onChange={props.handleInput}
        defaultValue={props.value}
        placeholder={props.placeholder}
      ></textarea>
    </div>
  );
}

export default TextArea;
