import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import "./About.css";

import SingleInput from "../../Input/SingleInput";
import DoubleInput from "../../Input/DoubleInput";
import BigModal from "../../Form/BigModal";
import ErrorMessage from "../../Error/ErrorMessage";

import { authAPIs } from "../../../api";

import user from "../../../Assets/user.svg";
import emailicon from "../../../Assets/email.png";
import SuccessMessageEmail from "../../Success/SuccessMessageEmail";

function About(props) {
  const navigate = useNavigate();
  const name = useRef();
  const language = useRef();
  const email = useRef();
  const designation = useRef();
  const password = useRef();
  const confirm_password = useRef();
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 450px)",
  });
  async function RegisterClient(bodyData) {
    try {
      const raw = await fetch(`${authAPIs.registerClient}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      });
      let res = await raw.json();
      if (res.status !== "authorized") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  function onSubmit() {
    if (!designation.current.value) {
      message.current = "Please provide designation!";
      setPasswordError(true);
      return;
    }
    if (password.current.value !== confirm_password.current.value) {
      message.current = "Password Does not match";
      setPasswordError(true);
      return;
    }

    const bodyData = {
      name: name.current?.value,
      email: email.current?.value,
      password: password.current?.value,
      designation: designation.current?.value,
      platform: "website",
    };
    RegisterClient(bodyData)
      .then((response) => {
        console.log(response);
        localStorage.setItem("token", response.token);
        localStorage.setItem("userId", response?.data?.userId);
        const companyid = response?.data?.companyid;
        const paymentid = response?.data?.paymentid;
        if (response.data.role !== "client") {
          navigate("/teamtable");
        } else if (!companyid) {
          props.GiveAboutData({ current: 2 });
        } else if (!paymentid) {
          props.GiveAboutData({ current: 3 });
        } else {
          navigate("/teamtable");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === "success") {
          message.current = err.message;
          setEmailVerify(true);
        } else {
          message.current = err.message;
          setPasswordError(true);
        }
      });
  }
  return (
    <div className="about">
      <h2>Enter Your Details</h2>
      <SingleInput
        src={user}
        label="Full Name"
        myref={name}
        type="text"
        placeholder="Your full name"
        inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
      />
      {/* <SingleInput src={user} label="Languages"  myref={language} type='text' placeholder='give name'/> */}
      <SingleInput
        src={emailicon}
        label="Email"
        myref={email}
        type="email"
        placeholder="Your email"
        inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
      />
      <SingleInput
        src={emailicon}
        label="Designation"
        myref={designation}
        required={true}
        type="text"
        placeholder="Designation"
        inputStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
        inputLabelStyle={isMobile ? { width: "calc(100% - 60px)" } : {}}
      />

      {!isMobile && (
        <DoubleInput
          src1={user}
          label1="Password"
          myref1={password}
          type1="password"
          placeholder1="Enter password"
          src2={user}
          label2="Confirm Password"
          myref2={confirm_password}
          type2="password"
          placeholder2="Confirm password"
        />
      )}
      {isMobile && (
        <SingleInput
          src={user}
          label="Password"
          myref={password}
          type="password"
          placeholder="Enter confirm password"
          inputStyle={{ width: "calc(100% - 60px)" }}
          inputLabelStyle={{ width: "calc(100% - 60px)" }}
        />
      )}
      {isMobile && (
        <SingleInput
          src={user}
          label="Confirm Password"
          myref={confirm_password}
          type="password"
          placeholder="Confirm password"
          inputStyle={{ width: "calc(100% - 60px)" }}
          inputLabelStyle={{ width: "calc(100% - 60px)" }}
        />
      )}
      <div className="next" onClick={onSubmit}>
        &#10095;
      </div>
      <div className="login-section">
        <p>
          Already have account? <span onClick={() => navigate("/")}>Login</span>{" "}
        </p>
      </div>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}

      {emailVerify ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <SuccessMessageEmail
            closeSuccess={() => {
              setEmailVerify(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default About;
