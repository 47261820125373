import React,{useRef,useState} from 'react';

import {SectionAPIs} from '../../api';
import BigModal from '../Form/BigModal';
import ErrorMessage from '../Error/ErrorMessage';

import cross from '../../Assets/cross.png';

function DeleteSection(props) {
    const message = useRef();
    const [passwordError,setPasswordError] = useState(false);
    const id = props.id;
    async function deleteSection() {
        try {
          const raw = await fetch(`${SectionAPIs.deleteSection(id)}`,
            {
              method: 'DELETE',
              headers: {'token':localStorage.getItem('token')},
            })
          let res = await raw.json();
          if (res.status !== "success") {
            // console.log(res.message);
            throw res;
          }
          return res;
        }
        catch (err) {
          throw err;
        }
    }
    function onDelete(){
      deleteSection().then(response => {
            console.log(response);
            props.deleteData(true);
      }).catch((err)=>{
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      })
    }
    return (
    <div className="delete-user">
        <div className='delete-img'>
            <img src= {cross}/>
        </div>
        <p>Are you sure you want to delete {props.sectionName} ?</p>
        <div className="btns">
            <button className="left-btn" onClick={()=>{props.deleteData(false);}}>Cancel</button>
            <button className="right-btn" onClick={onDelete}>Delete</button>
        </div>
        {passwordError ? <BigModal style={{width:'425px', height:'256px',marginTop:'251.15px'}}>
              <ErrorMessage 
                closeError={()=>{setPasswordError(false)}}
                message = {message.current}
              />
        </BigModal> : ""}
    </div>
  )
}

export default DeleteSection;