import React from 'react';

import Header from '../Components/Header/Header';
import LeftNavigationBar from '../Components/Navigation/LeftNavigationBar';
//import EditProject from '../Components/EditProject/EditProject';
import FroalaEditProject from '../Components/EditProject/FroalaEditor';

function EditProjectPage() {
    return (
        <div style={{marginTop:'79px',display: 'flex'}}>
            <Header/>
            <LeftNavigationBar/>
            <FroalaEditProject/> 
        </div>
  )
}

export default EditProjectPage;