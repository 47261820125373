import React from 'react';
import './SuccessMessage.css';
import email from '../../Assets/email-32.png';

function SuccessMessageEmail(props) {
  return (
    <div  className="error-message">
        <div className='error-img'>
            <img src= {email}/>
        </div>
        <p>{props.message}</p>
        <button onClick={()=>{props.closeSuccess(false);}}>Close</button>
    </div>
  )
}

export default SuccessMessageEmail;