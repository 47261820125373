import React,{useRef,useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import './CompanyDetail.css';

import SingleInput from '../../Input/SingleInput';
import DoubleInput from '../../Input/DoubleInput';
import TripleInput from '../../Input/TripleInput';
import BigModal from '../../Form/BigModal';
import ErrorMessage from '../../Error/ErrorMessage';

import {authAPIs} from '../../../api';

import user from '../../../Assets/user.svg';
import companyicon from '../../../Assets/company.png';
import emailicon from '../../../Assets/email.png';

function CompanyDetail(props) {
    const navigate = useNavigate();
    const name = useRef();
    const website = useRef();
    const Address1 = useRef();
    const Address2 = useRef();
    const state = useRef();
    const city = useRef();
    const postalcode = useRef();
    const message = useRef();
    const [passwordError,setPasswordError] = useState(false);
    const isMobile = useMediaQuery({
      query: '(max-width: 500px)'
    })
    async function CreateCompany(CompanyData) {
        try {
          const raw = await fetch(`${authAPIs.createCompany}`,
            {
              method: 'POST',
              headers: {'Content-Type': 'application/json','token': localStorage.getItem('token')},
              body: JSON.stringify(CompanyData),
            })
          let res = await raw.json();
          if (res.status !== "success") {
            // console.log(res.message);
            throw res;
          }
          return res;
        }
        catch (err) {
          throw err;
        }
      }
    function onSubmit(){
        const CompanyData = {
            'name':name.current?.value,
            'link':website.current?.value,
            'address1':Address1.current?.value,
            'address2':Address2.current?.value,
            'state':state.current?.value,
            'city':city.current?.value,
            'postalCode':postalcode.current?.value,
        }
        CreateCompany(CompanyData).then(response => {
            console.log(response);
            localStorage.setItem('companyId',response?.data?.company?._id);
            const paymentid = response?.data?.paymentid;
            if(!paymentid){
              props.GiveCompanyData({current:3});
            }
            else{
              navigate('/teamtable');
            }
          }).catch((err) => {
            message.current = err.message;
            console.error(err);
            setPasswordError(true);
          });
    }
    const inputStyle = {
      width:"493px",
    }
    const inputLabelStyle = {
      width:"493px",
    }
    return (
    <div className="company-detail">
        <h2>Enter Your Company Details</h2>
        <SingleInput 
            src={user} label="Company Name" myref={name} type='text' placeholder='Your company name' 
            inputStyle={isMobile?{width:'calc(100% - 60px)'}:inputStyle} 
            inputLabelStyle={isMobile?{width:'calc(100% - 60px)'}:inputLabelStyle}
        />
        <SingleInput 
            src={companyicon} label="Company Website (Optional)" myref={website} type='text' 
            placeholder='Your company website'inputStyle={isMobile?{width:'calc(100% - 60px)'}:inputStyle}
            inputLabelStyle={isMobile?{width:'calc(100% - 60px)'}:inputLabelStyle}
        />
        {!isMobile && <DoubleInput
            src1={user} label1="Address Line 1" myref1={Address1}  type1='text' placeholder1='Company Address'
            src2={user} label2="Address Line 2 (Optional)" myref2={Address2}  type2='text' placeholder2='Company Address'
            doubleStyle = {{width:'210px'}} bigDivStyle = {{width:'525px'}} doubleDivStyle = {{width:'262px'}}
        />}
        {isMobile && <SingleInput 
            src={user} label="Address Line 1" myref={Address1} type='text' placeholder='Company Address'
            inputStyle={{width:'calc(100% - 60px)'}} inputLabelStyle={{width:'calc(100% - 60px)'}}
        />}
        {isMobile && <SingleInput 
            src={user} label="Address Line 2 (Optional)" myref={Address2} type='text' placeholder='Company Address'
            inputStyle={{width:'calc(100% - 60px)'}} inputLabelStyle={{width:'calc(100% - 60px)'}}
        />}
        {!isMobile && <TripleInput 
            src1={emailicon} label1="State" myref1={state} type1='text' placeholder1='State'
            src2={emailicon} label2="City"  myref2={city} type2='text' placeholder2='City' 
            src3={emailicon} label3="Zip Code" myref3={postalcode}  type3='text' placeholder3='Zip code'
        />}
            
            
        {isMobile && <SingleInput 
            src={emailicon} label="State" myref={state} type='text' placeholder='State'
            inputStyle={{width:'calc(100% - 60px)'}} inputLabelStyle={{width:'calc(100% - 60px)'}}
        />}
         
        {isMobile && <SingleInput 
            src={emailicon} label="City"  myref={city} type='text' placeholder='City'
            inputStyle={{width:'calc(100% - 60px)'}} inputLabelStyle={{width:'calc(100% - 60px)'}}
        />}

        {isMobile && <SingleInput 
            src={emailicon} label="Zip Code" myref={postalcode} type='text' placeholder='Zip code'
            inputStyle={{width:'calc(100% - 60px)'}} inputLabelStyle={{width:'calc(100% - 60px)'}}
        />}





        <div className="next" onClick={onSubmit}>&#10095;</div>
        {passwordError ? <BigModal style={{width:'425px', height:'256px',marginTop:'251.15px'}}>
          <ErrorMessage 
            closeError={()=>{setPasswordError(false)}}
            message = {message.current}
          />
          </BigModal> : ""}
    </div>
  )
}

export default CompanyDetail
