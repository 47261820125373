import React from 'react';

import Header from '../Components/Header/Header';
import LeftNavigationBar from '../Components/Navigation/LeftNavigationBar';
import PaymentSubscriptionCard from '../Components/PaymentSubscription/PaymentSubscriptionCard';

function PaymentSubscription() {
    return (
        <div style={{marginTop:'79px',display: 'flex'}}>
            <Header/>
            <LeftNavigationBar/>
            <PaymentSubscriptionCard/>
        </div>
  )
}

export default PaymentSubscription;