import React, { useRef, useState, useEffect } from "react";
import "./ViewClient.css";

import { TeamProduct } from "../../api";

import AddAssigned from "./AddAssigned";
import BigModal from "../Form/BigModal";
import ErrorMessage from "../Error/ErrorMessage";

import add2 from "../../Assets/add2.png";
import del from "../../Assets/delete.png";

function ViewClient(props) {
  
  const [add, setAdd] = useState(false);
  const divheight = 51 * props.CurrentData?.assignees.length + 22 + "px";
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  async function deleteAssignee(id) {
    try {
      const raw = await fetch(
        `${TeamProduct.deleteAssignee(props.CurrentData?._id, id)}`,
        {
          method: "PATCH",
          headers: { token: localStorage.getItem("token") },
        }
      );
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    console.log(props.CurrentData?.assignees);
  }, [props.CurrentData?.assignees]);
  function onDelete(id) {
    deleteAssignee(id)
      .then((response) => {
        console.log(response);
        props.Change(true);
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      });
  }
  return (
    <div
      style={{
        width: "425px",
        height: "837.33px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="view-client">
        <div className="header-container">
          <div className="top">
            <p>Client Assigned</p>
            <img
              src={add2}
              onClick={() => {
                setAdd(true);
              }}
            />
          </div>
          <span
            onClick={() => {
              props.editView(true);
            }}
            className="fa fa-times"
          >
          </span>
        </div>
        <div className="client-name" style={{ height: divheight }}>
          {props.CurrentData?.assignees.map((data) => {
            return (
              <div className="name">
                <p>{data.name}</p>
                <img src={del} onClick={() => onDelete(data._id)} />
              </div>
            );
          })}
        </div>
      </div>
      {add ? (
        <BigModal
          style={{
            width: "425px",
            height: "363px",
            marginTop: "217.5px",
            zIndex: "10",
          }}
        >
          <AddAssigned
            id={props?.CurrentData?._id}
            onAdding={() => {
              setAdd(false);
              props.Change(true);
            }}
          />
        </BigModal>
      ) : (
        ""
      )}
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default ViewClient;
