import eye from "../../Assets/eye.png";

function View(props) {
  return (
    <div className="view-button">
      <img src={eye} />
      <button
        style={{ backgroundColor: "" }}
        onClick={() => {
          props.setView(true);
          props.currentdata.current = props.data;
        }}
      >
        View
      </button>
    </div>
  );
}

export default View;
