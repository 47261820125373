import React from 'react';
import './BigModal.css';
function BigModal(props) {
    return (<div className="big-modal-wrapper" style={props?.wrapperstyle}>
        <div className="big-modal-form" style={props?.style}>
            <div className="big-form-body" style={props?.bodyStyle}>
                {props.children}
            </div>
        </div>
    </div>);
}

export default BigModal;