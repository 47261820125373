import React, { useRef, useState } from "react";

import user from "../../Assets/user.svg";
import emailicon from "../../Assets/email.png";

import BigModal from "../Form/BigModal";
import Form from "../Form/v2/Form/Form";
import Input from "../Form/v2/Input/Input";
import ErrorMessage from "../Error/ErrorMessage";

import { PaymentAPIs } from "../../api";

function AddPaymentCard(props) {
  const message = useRef("");
  const [card, setCard] = useState({
    cardnumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [processing, setProcessing] = useState(false);

  async function AddCard(PaymentData) {
    try {
      if (processing)
        return {
          status: "fail",
          message: "Please wait another request is in progress!",
        };
      setProcessing(true);
      const raw = await fetch(`${PaymentAPIs.CardBilliingV2}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify(PaymentData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }

  function onSubmit() {
    const PaymentData = {
      type: "card",
      paymentMethod: {
        number: card.cardnumber,
        exp_month: card.expiryDate.split(" / ")[0],
        exp_year: card.expiryDate.split(" / ")[1],
        cvc: card.cvv,
      },
    };

    AddCard(PaymentData)
      .then((response) => {
        props.newCard(response?.data?.paymentMethod?.data);
        props.CardPayment();
      })
      .catch((err) => {
        message.current = err.message;
        setPasswordError(true);
      })
      .finally(() => setProcessing(false));
  }

  return (
    <Form title="Payment Method" close={() => props.CardPayment(false)}>
      <Input
        icon={user}
        label="Card Number"
        name="cardnumber"
        type="text"
        placeholder="Card No"
        value={card.cardnumber}
        handleInput={(e) => {
          if (card.cardnumber.length === 19 && e.target.value.length > 19) {
            e.target.value = card.cardnumber;
            return;
          }
          if (
            (card.cardnumber.length < 4 ||
              (card.cardnumber.length < 9 && card.cardnumber.length !== 5) ||
              (card.cardnumber.length < 14 &&
                card.cardnumber.length !== 10 &&
                card.cardnumber.length !== 5)) &&
            [4, 9, 14].includes(e.target.value.length)
          ) {
            e.target.value = e.target.value + " ";
          }
          card.cardnumber = e.target.value;
          setCard({ ...card });
        }}
      />

      <div className="small-input">
        <Input
          icon={emailicon}
          label="Expiry Date"
          type="text"
          placeholder="eg. 09 / 24"
          value={card.expiryDate}
          handleInput={(e) => {
            if (card.expiryDate.length === 7 && e.target.value.length > 7) {
              e.target.value = card.expiryDate;
              return;
            }

            if (
              card.expiryDate.length < 2 &&
              e.target.value.length == 2 &&
              e.target.value.length <= 3
            ) {
              e.target.value = `${e.target.value} / `;
            }
            card.expiryDate = e.target.value;
            setCard({ ...card });
          }}
        />
        <Input
          icon={emailicon}
          label="CVV"
          type="text"
          placeholder="CVV"
          value={card.cvv}
          handleInput={(e) => {
            if (card.cvv.length === 3 && e.target.value.length > 3) {
              e.target.value = card.cvv;
              return;
            }
            card.cvv = e.target.value;
            setCard({ ...card });
          }}
        />
      </div>
      <button onClick={onSubmit}>Next</button>
      <p className="form-text">
        {processing
          ? "Please wait adding new payment method. Do not close the window"
          : ""}
      </p>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </Form>
  );
}

export default AddPaymentCard;
