import React from 'react';
import './confirmPage.css';
import cross from '../../Assets/check2.png';

function ConfirmPage(props) {
  // console.log(props.successClose)
  return (
    <div  className="error-message">
        <div className='error-img'>
            <img src= {cross}/>
        </div>
        <p>{props.message}</p>
        <button onClick={()=>{props.closeSuccess(false); props.successClose(true)}}>Close</button>
    </div>
  )
}

export default ConfirmPage;