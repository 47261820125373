import React, { useRef, useState, useEffect } from "react";
import { SubscriptionAPIs, SubscriptionAPIsv2 } from "../../../api";
import "./Subscription.css";
import SubscriptionCard from "./SubscriptionCard";
function Subscription(props) {
  const subscriptionpack = useRef();
  const [subscriptionCard, SetSubscriptionCard] = useState();
  async function GetSubscriptionCard() {
    try {
      const raw = await fetch(`${SubscriptionAPIsv2.fetchPlans}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    GetSubscriptionCard()
      .then((response) => {
        SetSubscriptionCard(response.data.plans.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [Current, SetCurrent] = useState(1);
  function OnPrev() {
    if (Current > 1) {
      SetCurrent(Current - 1);
    } else {
      SetCurrent(subscriptionCard.length);
    }
  }
  function OnNext() {
    if (Current < subscriptionCard.length) {
      SetCurrent(Current + 1);
    } else {
      SetCurrent(1);
    }
  }
  function onSubmit() {
    const SubscriptionData = {
      "subscription-pack": subscriptionpack.current?.value,
      data: subscriptionCard,
      current: 4,
    };
    console.log("subscription button clicked 2");

    props.GiveSubscriptionData(SubscriptionData);
    // console.log(SubscriptionData)
  }
  var id = 0;
  return (
    <div className="subscription">
      <h2>Select A Subscription</h2>

      <div className="carousel">
        {subscriptionCard?.map((data) => {
          return (
            <SubscriptionCard
              data={data}
              GiveSubsData={props.GiveSubscriptionData}
            />
          );
        })}
      </div>
      <p
        style={{
          color: "grey",
          fontSize: "0.9rem",
          textAlign: "center",
          marginTop: "10px",
        }}
      >
        You won't be charged unless the free trial ends. You can unsubscribe at
        any point.
      </p>
      {/* <div className="next" onClick={onSubmit}>&#10095;</div> */}
    </div>
  );
}

export default Subscription;
