import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./TeamProductCard.css";

import { TeamProduct, CategoryAPIs } from "../../api";

import Modal from "../Form/v2/Modal/Modal";
import BigModal from "../Form/BigModal";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import DeleteUser from "../TeamTable/DeleteUser";
import ViewClient from "./ViewClient";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";

import add from "../../Assets/add.png";
import Sort from "../../Assets/Sort.png";
import dropdown from "../../Assets/dropdown.png";
import dropup from "../../Assets/d.png";
import cloud from "../../Assets/cloud.png";
import check from "../../Assets/check3.png";
import View from "./View";

function TeamProductCard() {
  const navigate = useNavigate();
  const currentdeleteid = useRef();
  const currentdata = useRef();
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [view, setView] = useState(false);
  const [teamProductCardData, setTeamProductCardData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [change, setChange] = useState(false);
  const [sort, setSort] = useState("name");
  const [categories, setCategories] = useState([]);

  const updateProductList = (product) => {
    const productIndex = teamProductCardData.findIndex(
      (prod) => `${prod._id}` === `${product._id}`
    );
    if (productIndex < 0) return;
    teamProductCardData[productIndex] = product;
    setTeamProductCardData(teamProductCardData);
  };

  async function GetSubClient() {
    try {
      const raw = await fetch(`${TeamProduct.GetProducts(sort)}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  async function CountPrdouct(api) {
    try {
      const raw = await fetch(api, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    // console.log('change');
    GetSubClient().then((response) => {
      if (currentdata.current?._id) {
        currentdata.current = response.data.products.filter(function (cd) {
          return cd._id === currentdata.current?._id;
        })[0];
      }
      setTeamProductCardData(response.data.products);
    });
    CountPrdouct(`${TeamProduct.countTotalProducts}`).then((response) => {
      setTotalCount(response.data.products);
    });
    CountPrdouct(`${TeamProduct.countUpdatedProducts}`).then((response) => {
      setUpdateCount(response.data.products);
    });
  }, [adding, deleting, change, sort]);
  function giveDate(date) {
    var d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}/${year}`;
  }

  // console.log(teamProductCardData)

  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);

  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});

  /**
   * This function gets called when show/hide link is clicked.
   */
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== userId)
      : currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  };

  function getStatus(product) {
    if (product.blocked)
      return {
        status: "Blocked",
        style: {
          backgroundColor: "#ffe6e6",
          color: "red",
        },
      };

    if (product.active)
      return {
        status: "Published",
        style: {},
      };

    return {
      status: "Draft",
      style: {
        backgroundColor: "rgba(206, 151, 239, 0.29)",
        color: "#6A49AF",
      },
    };
  }

  //Fetch Categories
  useEffect(() => {
    fetch(`${CategoryAPIs.fetchCategories}`, {
      method: "GET",
      headers: { token: localStorage.getItem("token") },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== "success") throw res;
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="teamProductCard">
      <div className="top">
        <div className="left-side">
          <h2>Product Table</h2>
          <p>The following table shows the products in inventory</p>
        </div>
        <div className="right-side">
          <div>
            <div>
              <p>Published Products</p>
              <p>{updateCount}</p>
            </div>
            <img src={cloud} />
          </div>
          <div>
            <div>
              <p>Total Products</p>
              <p>{totalCount}</p>
            </div>
            <img src={check} style={{ width: "16px", height: "10.25px" }} />
          </div>
        </div>
      </div>
      <div
        className="btn"
        onClick={() => {
          setAdding(true);
        }}
      >
        <img src={add} />
        <button style={{ background: add }}>Add</button>
      </div>
      <div className="filters">
        {/* <img src={Download} /> */}
        <img
          src={Sort}
          onClick={() => {
            if (sort === "name") {
              setSort("-name");
            }
            if (sort === "-name") {
              setSort("name");
            }
          }}
        />
        {/* <img src={Filter} /> */}
      </div>
      <div className="table-container">
        {teamProductCardData.length ? (
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: "150px",
                    paddingLeft: "13px",
                    textAlign: "left",
                  }}
                >
                  <label htmlFor="employee">Product Name</label>
                </th>
                <th style={{ width: "154px" }}>Product Model</th>
                <th style={{ width: "100px" }}>Status</th>
                <th style={{ width: "139px" }}>Category</th>
                <th style={{ width: "136px" }}>Sub-Category</th>
                <th style={{ width: "136px" }}>Assignee</th>
                <th style={{ width: "136px" }}>Added Date</th>
                <th style={{ width: "137px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {teamProductCardData.map((data, id) => (
                <>
                  <tr key={id}>
                    <td
                      style={{
                        width: "150px",
                        paddingLeft: "13px",
                        textAlign: "left",
                      }}
                    >
                      <img
                        src={expandState[data._id] ? dropup : dropdown}
                        onClick={(event) => handleEpandRow(event, data._id)}
                        style={{ marginRight: "10px", cursor: "pointer" }}
                      />
                      <span
                        onClick={() => {
                          navigate(`${data._id}`);
                        }}
                        htmlFor="employee"
                        style={{ textTransform: "capitalize" }}
                      >
                        {data.name}
                      </span>
                    </td>
                    <td style={{ width: "154px" }}>{data.model}</td>
                    <td style={{ width: "100px" }}>
                      <div style={getStatus(data).style}>
                        {getStatus(data).status}
                      </div>
                    </td>
                    <td style={{ width: "139px" }}>{data.category}</td>
                    <td style={{ width: "136px" }}>{data.subcategory}</td>
                    <td style={{ width: "136px" }}>
                      <View
                        setView={setView}
                        currentdata={currentdata}
                        data={data}
                      />
                      {/*<img src={eye} />
                      <button
                        style={{ backgroundColor: "" }}
                        onClick={() => {
                          setView(true);
                          currentdata.current = data;
                        }}
                      >
                        View
                      </button>*/}
                    </td>
                    <td style={{ width: "136px" }}>
                      {giveDate(data.createdAt)}
                    </td>
                    <td id="td-buttons" style={{ width: "137px" }}>
                      <button>
                        <FontAwesomeIcon
                          onClick={() => {
                            currentdeleteid.current = data._id;
                            setDeleting(true);
                          }}
                          icon={faTrash}
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                      {/* <button>
                  <FontAwesomeIcon icon={faSnowflake} />
                </button> */}
                      <button>
                        <FontAwesomeIcon
                          icon={faPencil}
                          onClick={() => {
                            currentdata.current = data;
                            setEditing(true);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </button>
                    </td>
                  </tr>
                  {expandedRows.includes(data._id) ? (
                    <tr>
                      <td colspan="8">
                        <div
                          className="section"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#9AA6B9",
                            padding: "10px",
                          }}
                        >
                          <tr>
                            <th>Section Name</th>
                            <th>Comments</th>
                          </tr>
                          {data.sections.map((item, key) => {
                            return (
                              <tr>
                                <td>{item.name}</td>
                                <td>{item.comment || "no comments"}</td>
                              </tr>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {/* {showTable ? (
                  <table className="sectiontable">
                    <thead>
                      <tr>
                        <th>Section Name</th>
                        <th>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>section 1</td>
                        <td>comment here</td>
                      </tr>
                      <tr>
                        <td>section 2</td>
                        <td>comment here</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  ""
                )} */}
                </>
              ))}
            </tbody>
          </table>
        ) : (
          <p
            style={{
              display: "block",
              width: "fit-content",
              whiteSpace: "nowrap",
              padding: "50px 20px",
              fontSize: "0.9rem",
              color: "grey",
              textAlign: "center",
              margin: "auto",
            }}
          >
            There is no product in the inventory please add the product
          </p>
        )}
      </div>
      {adding ? (
        <Modal>
          <AddProduct
            userData={() => {
              setAdding(false);
            }}
            categories={categories}
          />
        </Modal>
      ) : (
        ""
      )}
      {deleting ? (
        <BigModal
          style={{ width: "425px", height: "290px", marginTop: "254.15px" }}
        >
          <DeleteUser
            id={currentdeleteid.current}
            deleteData={(value) => {
              setDeleting(false);
            }}
            apiLink={TeamProduct.deleteProducts}
            title={"product"}
          />
        </BigModal>
      ) : (
        ""
      )}
      {editing ? (
        <Modal>
          <EditProduct
            editData={(value) => {
              setEditing(false);
              if (!value) return;
              const productIndex = teamProductCardData.findIndex(
                (prod) => `${prod._id}` === `${currentdata.current._id}`
              );
              teamProductCardData[productIndex] = {
                ...teamProductCardData[productIndex],
                ...value,
              };
              setTeamProductCardData([...teamProductCardData]);
            }}
            CurrentData={currentdata.current}
            updateProductList={updateProductList}
            categories={categories}
          />
        </Modal>
      ) : (
        ""
      )}
      {view ? (
        <BigModal style={{ width: "425px", height: "837.33px" }}>
          <ViewClient
            editView={(value) => {
              setView(false);
            }}
            CurrentData={currentdata.current}
            Change={() => {
              setChange(!change);
            }}
          />
        </BigModal>
      ) : (
        ""
      )}

      {/* {showTable ? (
        <table>
          <thead>
            <tr>
              <th>Section Name</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      ) : (
        ""
      )} */}
    </div>
  );
}
export default TeamProductCard;
