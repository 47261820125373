import React,{useRef,useState} from 'react';
import './DeleteUser.css';

import BigModal from '../Form/BigModal';
import ErrorMessage from '../Error/ErrorMessage';

import cross from '../../Assets/cross.png';

function DeleteUser(props) {
    const id = props.id;
    const message = useRef();
    const [passwordError,setPasswordError] = useState(false);
    async function deleteSubClient() {
        try {
          const raw = await fetch(`${props.apiLink(id)}`,
            {
              method: 'DELETE',
              headers: {'token':localStorage.getItem('token')},
            })
          let res = await raw.json();
          if (res.status !== "success") {
            // console.log(res.message);
            throw res;
          }
          return res;
        }
        catch (err) {
          throw err;
        }
    }
    function onDelete(){
        deleteSubClient(id).then(response => {
            console.log(response);
            props.deleteData(false);
        }).catch((err)=>{
          message.current = err.message;
          console.error(err);
          setPasswordError(true);
        })
    }
    return (
    <div className="delete-user">
        <div className='delete-img'>
            <img src= {cross}/>
        </div>
        <p>Do you want to delete the {props.title} ?</p>
        <div className="btns">
            <button className="left-btn" onClick={onDelete}>Yes</button>
            <button className="right-btn" onClick={()=>{props.deleteData(false);}}>No</button>
        </div>
        {passwordError ? <BigModal style={{width:'425px', height:'256px',marginTop:'251.15px'}}>
              <ErrorMessage 
                closeError={()=>{setPasswordError(false)}}
                message = {message.current}
              />
        </BigModal> : ""}
    </div>
  )
}

export default DeleteUser;