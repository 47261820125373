import React from "react";
import "./DoubleInput.css";
function DoubleInput(props) {
  return (
    <div className="double-input" style={props?.bigDivStyle}>
      <div className="double-input-left" style={props?.doubleDivStyle}>
        <div>
          <img src={props.src1} />
          <p>{props.label1}</p>
        </div>
        <input
          style={props?.doubleStyle}
          ref={props.myref1}
          type={props.type1}
          placeholder={props.placeholder1}
          defaultValue={props.value1}
          readOnly={props.readOnly1}
        />
      </div>
      <div className="double-input-right" style={props?.doubleDivStyle}>
        <div>
          <img src={props.src2} />
          <p>{props.label2}</p>
        </div>
        <input
          style={props.doubleStyle}
          ref={props.myref2}
          type={props.type2}
          placeholder={props.placeholder2}
          defaultValue={props.value2}
          readOnly={props.readOnly2}
        />
      </div>
    </div>
  );
}

export default DoubleInput;
