import React from 'react';
import './SuccessMessage.css';
import cross from '../../Assets/check2.png';

function SuccessMessage(props) {
  return (
    <div  className="error-message">
        <div className='error-img'>
            <img src= {cross}/>
        </div>
        <p>{props.message}</p>
        <button onClick={()=>{props.closeSuccess(false);}}>Close</button>
    </div>
  )
}

export default SuccessMessage;