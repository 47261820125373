import React from "react";
import './modal.css';

function Modal(props) {

    return (<div className="modal-wrapper">
        <div className="modal-form" style={props?.style}>
            <div className="form-body" style={props?.bodyStyle}>
                {props.children}
            </div>
        </div>
    </div>);
}

export default Modal;