import { React, useState, useRef } from "react";
import "./SubscriptionCard.css";
import check from "../../../Assets/check.svg";
import add from "../../../Assets/add4.png";
import minus from "../../../Assets/minus.png";
import BigModal from "../../Form/BigModal";
import ErrorMessage from "../../Error/ErrorMessage";
import ConfirmPage from "../../confirmationPage/confirmPage";

function SubscriptionCard(props) {
  const SubscriptionData = props?.data;
  const DummyData = props?.DummyData;
  const message = useRef();
  const [quantity, setQuantity] = useState(1);
  const [pageError, setPageError] = useState(false);
  const [pageSuccess, setPageSuccess] = useState(false);

  const getPlanDay = (renewal) => {
    return {
      day: "daily",
      month: "monthly",
      quarter: "quaterly",
      year: "yearly",
    }[renewal];
  };

  const details = {
    monthly: {
      description:
        "Start here if you want to experiment with Green Manual but not completely sure of a long term commitment.",
      features: [
        "First 30 days are free",
        "Cancel anytime with no commitment",
        "Get support to create the right post sale offerings for your customers",
      ],
    },
    yearly: {
      description: "Continue here if you want to set your customers up for a great post sale experience in the long run.",
      features: [
        "First 30 days are free",
        "Save more on long term engagement",
        "Get support to create the right post sale offerings for your customers",
      ],
    },
  };

  function onSubmit() {
    const trailData = {
      priceId: SubscriptionData.priceId,
      qty: quantity,
    };

    window.localStorage.setItem("trial", JSON.stringify(trailData));
    props.GiveSubsData({ current: 4 });
  }
  return (
    <div className="SubscriptionCard1" style={props.style}>
      <p style={props?.topTypeStyle} className="type">
        {SubscriptionData?.plan}
      </p>
      <p style={props?.topHeadingStyle1} className="price">
        <span className="dollar">$</span>
        {SubscriptionData?.amount / 100}
        <span className="per" style={{ fontSize: "0.7rem" }}>
          /{getPlanDay(SubscriptionData.renewInterval)}
        </span>
      </p>
      <p style={props?.topHeadingStyle2} className="details">
        {details[getPlanDay(SubscriptionData.renewInterval)].description}
      </p>
      <ul>
        {details[getPlanDay(SubscriptionData.renewInterval)].features.map(
          (data, index) => {
            return (
              <li key={index}>
                <div>
                  <img src={check} />
                </div>
                <p>{data}</p>
              </li>
            );
          }
        )}
      </ul>
      <div className="quantity">
        <div
          className="minus"
          onClick={() => {
            if (quantity > 1) setQuantity(quantity - 1);
          }}
        >
          <img src={minus} />
        </div>
        <div className="number">{quantity}</div>
        <div
          className="plus"
          onClick={() => {
            setQuantity(quantity + 1);
          }}
        >
          <img src={add} />
        </div>
      </div>
      <div className="btns" style={props?.allButtonstyles}>
        <button style={props?.buttonstyle} onClick={onSubmit}>
          Try Now
        </button>
      </div>
      {pageSuccess ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ConfirmPage
            closeSuccess={() => {
              setPageSuccess(false);
            }}
            message={message.current}
            // successClose = {()=>{setSuccessClosed(true)}}
          />
        </BigModal>
      ) : (
        ""
      )}
      {pageError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPageError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default SubscriptionCard;
