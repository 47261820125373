import React from "react";
import "./modal.css";
function Modal(props) {
  return (
    <div className="modal-wrapper" style={props?.wrapperstyle}>
      {props.children}
    </div>
  );
}

export default Modal;
