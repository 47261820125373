import React, { useState } from "react";

import ChooseMethod from "./ChooseMethod";
import AddPaymentCard from "./AddPaymentCard";
import AddPaymentBank from "./AddPaymentBank";

function PaymentMethod(props) {
  const style = {
    width: "1078px",
    height: "643px",
    margin: "97px auto auto auto",
    display: "flex",
    border: "none",
    paddingTop: "1px",
  };
  return (
    <div className="payment-method" style={style}>
      <AddPaymentCard
        CardPayment={() => {
          props.getMethodData(true);
        }}
        newCard={props.newCard}
      />
    </div>
  );
}

export default PaymentMethod;
