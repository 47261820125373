import React from 'react'
import Header from '../Components/Header/Header';
import LeftNavigationBar from '../Components/Navigation/LeftNavigationBar';
import ProfileCard from '../Components/Profile/ProfileCard';
import { useAuth } from '../Components/Auth/Auth';

function Profile() {
  const data = useAuth();
    console.log(data)
  return (
    <div style={{marginTop:'79px',display: 'flex'}}>
        <Header/>
        <LeftNavigationBar />
        <ProfileCard data={data} />
    </div>
  )
}

export default Profile