import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./AddAssigned.css";

import { TeamTable, TeamProduct } from "../../api";
import BigModal from "../Form/BigModal";
import ErrorMessage from "../Error/ErrorMessage";

import add3 from "../../Assets/add3.png";
import circle from "../../Assets/circle.png";
import dropdown from "../../Assets/dropdown.png";

function AddAssigned(props) {
  const [subclient, setSubclient] = useState("");
  const loading = useRef(false);
  const [subclientOptions, setSubclientOptions] = useState([]);
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  async function getSubClient() {
    try {
      const raw = await fetch(`${TeamTable.SubClients("name")}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    getSubClient()
      .then((response) => {
        setSubclientOptions(
          response.data.subclients.map((data) => ({
            value: data.name,
            label: data.name,
            id: data._id,
          }))
        );
      })
      .catch((err) => {
        message.current = err.message;
        console.error(err);
        setPasswordError(true);
      });
  }, []);
  async function AddAssigne(id) {
    try {
      loading.current = true;
      const raw = await fetch(`${TeamProduct.addAssignee(props.id)}`, {
        method: "PATCH",
        headers: {
          token: localStorage.getItem("token"),
          "content-type": "application/json",
        },
        body: JSON.stringify({ assigneeId: id }),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  function onAdd() {
    //console.log(subclient);
    //console.log(loading.current);
    if (!loading.current) {
      AddAssigne(subclient.id)
        .then((response) => {
          console.log(response);
          props.onAdding(true);
          loading.current = false;
        })
        .catch((err) => {
          loading.current = false;
          message.current = err.message;
          console.error(err);
          setPasswordError(true);
        });
    }
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      width: "351.04px",
      height: "58.43px",
      background: "#FFFFFF",
      border: "2.19403px solid #DEDEDE",
      borderRadius: "9.55224px",
      fontSize: "18px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#b8b6b6",
      },
      "&:focus": {
        borderColor: "yellow",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      background: state.isSelected ? "#60B893" : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "black",
      padding: 20,
      "&:hover": {
        background: "#60B893",
        color: "#FFFFFF",
      },
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <div className="add-assignee">
      <div className="top-img">
        <img src={circle} />
        <img src={add3} />
      </div>
      <p>Assign user to the product</p>
      <div className="assignee-input">
        <Select
          options={subclientOptions}
          styles={customStyles}
          placeholder={"User Name"}
          client={subclient}
          onChange={(client) => {
            setSubclient(client);
          }}
        />
      </div>
      <div className="btns">
        <button
          className="left-btn"
          onClick={() => {
            props.onAdding(false);
          }}
        >
          Cancel
        </button>
        <button className="right-btn" onClick={onAdd}>
          Add
        </button>
      </div>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddAssigned;
