import React,{useRef} from 'react';
import './EditUser.css';

import {TeamTable} from '../../api';

function EditUser(props) {
    const name = useRef();
    // const password = useRef();
    // const email = useRef();
    async function EditSubClient(bodyData) {
      try {
        const raw = await fetch(`${TeamTable.editSubClients(props.edit._id)}`,
          {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json','token':localStorage.getItem('token')},
            body: JSON.stringify(bodyData),
          })
        let res = await raw.json();
        if (res.status !== "success") {
          // console.log(res.message);
          throw res;
        }
        return res;
      }
      catch (err) {
        throw err;
      }
    }
    function onSubmit(){
      const bodyData ={
        'name':name.current?.value,
        // 'email':email.current?.value,
        // 'password':password.current?.value,
      }

      console.log(bodyData)
      EditSubClient(bodyData).then(response => {
        console.log(response);
        props.editData(false);
      }).catch((err) => {
        console.error(err);
      });
    }
    // console.log(props.edit)
    return (
      <div className="editUser">
          <div className='header-container'>
           <h2>Edit User</h2>
           {/* <span onClick={()=> props.editData(false)}>Close</span> */}
          </div>
          <div className='input-container'>
            <input type='text' placeholder='Name' ref={name} defaultValue={props?.edit?.name} />
            {/* <input type='email' placeholder='Email' ref={email} defaultValue={props?.edit?.email}/> */}
            {/* <input type='password' placeholder='Password' ref={password}/> */}
          </div>
          <div className='footer-container'>
            <div className='btn' onClick={()=> props.editData(false)}>
                <button className='btn'>Cancel</button>
            </div>
            <div className='btn' onClick={onSubmit}>
                <button className='btn'>Edit</button>
            </div>
          </div>
      </div>
    )
  }

export default EditUser