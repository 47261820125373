import React from 'react';
import { useNavigate} from 'react-router-dom';
import './SignupComplete.css';

import check from '../../Assets/check.svg';

function SignupComplete() {
  const navigate = useNavigate();
  return (
    <div className="signup-complete">
       <h2>Finishing Up</h2>
       <p>Almost Done</p>
       <div><img src={check}/></div>
       <p>You are ready to explore</p>
       <button onClick={() => {navigate('/teamtable')}}>Go to Dashboard</button>
    </div>
  )
}

export default SignupComplete