import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./LeftNavigationBar.css";

import user from "../../Assets/user.png";
import grid from "../../Assets/grid.png";
import payment from "../../Assets/payment.png";
import { useAuth } from "../Auth/Auth";

function LeftNavigationBar() {
  const navigate = useNavigate();
  const userData = useAuth();
  const [activeNav, setActiveNav] = useState(false);
  const role = userData?.user?.role;
  return (
    <div className={activeNav ? "leftNavigationBar active" : "leftNavigationBar"}>
      {role === "client" && (
        <div
          className="user"
          style={{ marginTop: "10px" }}
          onClick={() => {
            if (role === "client") navigate("/teamtable");
          }}
        >
          <img src={user} />
          Team
        </div>
      )}

      <div
        className="grid"
        onClick={() => {
          navigate("/teamproduct");
        }}
      >
        <img src={grid} />
        Inventory
      </div>
      {role === "client" && (
        <div
          className="nav-payment"
          style={{ marginTop: "-0.5px" }}
          onClick={() => {
            navigate("/paymentsubscription");
          }}
        >
          <img src={payment} />
          Payment Subscription
        </div>
      )}

      <div class="open" onClick={() => setActiveNav(!activeNav)}>
        {activeNav ? "<" : ">"}
      </div>
    </div>
  );
}

export default LeftNavigationBar;
