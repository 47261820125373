import React,{useRef,useState} from 'react';
import'./AddQr.css';
import qrIcon from '../../Assets/fi-rr-vector.png';
import qr from '../../Assets/qr.png';
import jpeg from '../../Assets/fi-rr-sign-in.png'

// import BigModal from '../../Form/BigModal';
// import ErrorMessage from '../../Error/ErrorMessage';
// import {TeamProduct} from '../../../api';

// import InputTextArea from '../../Input/InputTextArea';
// import DoubleInput from '../../Input/DoubleInput';

// import user from '../../../Assets/user.svg';

function AddQr(props) {
    const productname = useRef();
    const productmodel = useRef();
    const prodcutcategory = useRef();
    const prodcutsubcategory = useRef();
    const Productdescription = useRef();
    const message = useRef();
    const [passwordError,setPasswordError] = useState(false);
    // async function AddProduct(PaymentData) {
    //     try {
    //       const raw = await fetch(`${TeamProduct.addProducts}`,
    //         {
    //           method: 'POST',
    //           headers: {'Content-Type': 'application/json','token':localStorage.getItem('token')},
    //           body: JSON.stringify(PaymentData),
    //         })
    //       let res = await raw.json();
    //       if (res.status !== "success") {
    //         // console.log(res.message);
    //         throw res;
    //       }
    //       return res;
    //     }
    //     catch (err) {
    //       throw err;
    //     }
    // }
    // function onSubmit(){
    //     const PaymentData = {
    //         'name':productname.current?.value,
    //         'model':productmodel.current?.value,
    //         'category':prodcutcategory.current?.value,
    //         'subcategory':prodcutsubcategory.current?.value,
    //         'description':Productdescription.current?.value,
    //     }
    //     AddProduct(PaymentData).then(response => {
    //         console.log(response);
    //         props.GiveData(PaymentData);
    //       }).catch((err) => {
    //         message.current = err.message;
    //         console.error(err);
    //         setPasswordError(true);
    //       });
    // }
    const inputStyle={
      width:'811px',
      paddingTop:'12px',
      height:'73px',
      background:'#F5F5F5',
      borderRadius:'16px',
    };
    const inputLabelStyle={
        width:'811px',
    };
    const doubleStyle ={
      width:'365px',
      background:'#F5F5F5',
    }
    return (
    <div className="addqr">
       <div className='close-container'>
          <div className='close' ><img src={qrIcon}/></div>
       </div>
       <div className='qr'>
         <img src={props.qrdata} alt="qr"/>
       </div>

       <div className='button-container'>
         <button className='cancel' onClick={()=>props.userData(false)}>Cancel</button>
         <a href={props.qrdata} download><button className='jpeg'> <img src={jpeg} alt="jpeg"/> PNG</button></a>
       </div>
    </div>
  )
}

export default AddQr;