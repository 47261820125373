import React from 'react';

import Header from '../Components/Header/Header';
import LeftNavigationBar from '../Components/Navigation/LeftNavigationBar';
import TeamTableCard from '../Components/TeamTable/TeamTableCard';

function TeamTable() {
  return (
    <div style={{marginTop:'79px',display: 'flex'}}>
        <Header/>
        <LeftNavigationBar/>
        <TeamTableCard/>
    </div>
  )
}

export default TeamTable