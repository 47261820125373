import React, { useEffect, useState } from "react";
import "./AddPlan.css";
import SubscriptionCard from "./SubscriptionCard";
import { SubscriptionAPIsv2 } from "../../../api";

function AddPlan(props) {
  const [plans, setPlans] = useState([]);
 
  async function GetSubscriptionCard() {
    try {
      const raw = await fetch(`${SubscriptionAPIsv2.fetchPlans}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    GetSubscriptionCard()
      .then((response) => {
        setPlans(response?.data?.plans);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const SubStyle = {
    width: "257.14px",
    paddingLeft: "13px",
    height: "385.26px",
    background: "#FFFFFF",
    borderRadius: "32.4915px",
  };
  const buttonstyle = {
    width: "91.9px",
    height: "29.71",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "11.1399px",
    lineHeight: "118.5%",
  };
  const topHeadingStyle1 = {
    position: "relative",
    right: "10px",
  };
  const topHeadingStyle2 = {
    position: "relative",
    right: "20px",
  };
  var id = 0;
  return (
    <div className="add-product">
      <div className="close-container">
        <span onClick={() => props.close(false)} className="fa fa-times"></span>
      </div>
      <h2>Add Product</h2>
      {/* <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit</p> */}
      <div className="main-container">
        {plans?.map((data) => {
          id++;
          return (
            <div className="sub-card">
              {/* <img src={checkCircle} /> */}
              <SubscriptionCard
                data={data}
                style={SubStyle}
                allButtonstyles={{
                  position: "relative",
                  bottom: "5px",
                  width: "200px",
                  right: "7px",
                }}
                topHeadingStyle1={topHeadingStyle1}
                topHeadingStyle2={topHeadingStyle2}
                buttonstyle={buttonstyle}
                topTypeStyle={{ marginLeft: "70px", width: "188.45px" }}
                GiveData={props.GiveData}
              />
            </div>
          );
        })}
      </div>
      {/* <button onClick={onSubmit}>Next</button> */}
    </div>
  );
}

export default AddPlan;
