import React,{useRef,useState} from 'react';
import {SectionAPIs} from '../../api';

import BigModal from '../Form/BigModal';
import ErrorMessage from '../Error/ErrorMessage';

import add3 from '../../Assets/add3.png';
import circle from '../../Assets/circle.png';

function AddSection(props) {
  const id = props.id;
  const sectionName = useRef(null);
  const message = useRef();
  const [passwordError,setPasswordError] = useState(false);
  async function addSection() {
      try {
        const raw = await fetch(`${SectionAPIs.addSection}`,
          {
            method: 'POST',
            headers: {token:localStorage.getItem('token'),"content-type":"application/json"},
            body: JSON.stringify({"productId":props.ProdcutId,"name":sectionName.current.value}),
          })
        let res = await raw.json();
        if (res.status !== "success") {
          // console.log(res.message);
          throw res;
        }
        return res;
      }
      catch (err) {
        throw err;
      }
  }
  function onAdd(){
    addSection().then(response => {
        console.log(response);
        props.setSections(response.data.section);
    }).catch((err)=>{
      message.current = err.message;
      console.error(err);
      setPasswordError(true);
    })
  }
  return (
    <div className="add-assignee" style={{height:'380px'}}>
        <div className="top-img">
            <img src={circle}/>
            <img src={add3}/>
        </div>
        <p style={{width:'280px'}}>Enter the section name you want to create</p>
        <div className='assignee-input' style={{marginTop:'52px'}}>
          <label for="sectionName"style={{fontSize:'16.72px',fontColor:'black',fontWeight:'500',float:'left'}}>Section Name</label>
          <input type="text" name="sectionName" style={{marginTop:'5px'}} ref={sectionName}/>
        </div>
        <div className="btns" style={{marginTop:'63px'}}>
            <button className="left-btn" onClick={() => {props.onAdding(false)}}>Cancel</button>
            <button className="right-btn" onClick={onAdd}>Add</button>
        </div>
        {passwordError ? <BigModal style={{width:'425px', height:'256px',marginTop:'251.15px'}}>
              <ErrorMessage 
                closeError={()=>{setPasswordError(false)}}
                message = {message.current}
              />
        </BigModal> : ""}
    </div>
  )
}

export default AddSection;
