import { useState } from "react";
import "./input.css";

function Select(props) {
  const [active, setActive] = useState(false);
  return (
    <div className="modal-form-input">
      <div className="label">
        <img src={props.icon} alt="user" />
        <label>{props.label}</label>
      </div>
      <div className="main-style select-style">
        <p
          onClick={() => setActive(!active)}
          style={{ color: props.selectedValue ? "black" : "grey" }}
        >
          <span>{props.selectedValue || props.placeholder} </span>
          <i
            style={{ margin: "auto 0" }}
            className={`fa fa-angle-${active ? "down" : "left"}`}
          ></i>
        </p>
        <ul className={active ? "option-list active" : "option-list"}>
          {props.options.map((option, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  props.selectValue(option);
                  setActive(!active);
                }}
              >
                {option.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Select;
