import React, { useState, useEffect, useRef } from "react";
import "./TeamTableCard.css";

import Modal from "../Form/Modal";
import BigModal from "../Form/BigModal";

import AddUser from "./AddUser";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";

import { TeamTable } from "../../api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";

import add from "../../Assets/add.png";
import Sort from "../../Assets/Sort.png";

function TeamTableCard() {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [teamTableCardData, setTeamTableCardData] = useState([]);
  const [userData, setUserData] = useState();
  const [editData, setEditData] = useState();
  const currentName = useRef();
  const currentPassword = useRef();
  const currentEmail = useRef();
  const [sort, setSort] = useState("name");
  var currentdeleteid = useRef();
  async function GetSubClient() {
    try {
      const raw = await fetch(`${TeamTable.SubClients(sort)}`, {
        method: "GET",
        headers: { token: localStorage.getItem("token") },
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  useEffect(() => {
    GetSubClient().then((response) => {
      setTeamTableCardData(response?.data?.subclients);
    });
  }, [adding, deleting, editing, sort]);

  function giveDate(date) {
    var d = new Date(date);
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${day}/${month}/${year}`;
  }
  // async function deleteSubClient(id) {
  //     try {
  //       const raw = await fetch(`${TeamTable.deleteSubClients(id)}`,
  //         {
  //           method: 'DELETE',
  //           headers: {'token':localStorage.getItem('token')},
  //         })
  //       let res = await raw.json();
  //       if (res.status !== "success") {
  //         // console.log(res.message);
  //         throw res;
  //       }
  //       return res;
  //     }
  //     catch (err) {
  //       throw err;
  //     }
  // }
  function onDelete(id) {
    currentdeleteid.current = id;
    setDeleting(true);
  }

  function onEdit(data) {
    setEditData(data);
    setEditing(true);
  }

  return (
    <div className="teamTableCard">
      <h2>Team Table</h2>
      <p>The following table shows the sub-clients</p>
      <div
        className="btn"
        onClick={() => {
          setAdding(true);
        }}
      >
        <img src={add} />
        <button style={{ background: add }}>Add</button>
      </div>
      <div className="filters">
        {/* <img src={Download}/> */}
        <img
          src={Sort}
          onClick={() => {
            if (sort === "name") {
              setSort("-name");
            }
            if (sort === "-name") {
              setSort("name");
            }
          }}
        />
        {/* <img src={Filter}/> */}
      </div>
      <div className="table-container">
        {teamTableCardData.length ? (
          <table>
            <thead>
              <tr>
                <th style={{ width: "35px", textAlign: "center" }}>
                  {/*<input type="checkbox" name="employee"/> */}
                  <label htmlFor="employee">ID</label>
                </th>
                <th style={{ width: "215px", paddingLeft: "12px" }}>
                  Employee Name
                </th>
                <th style={{ width: "305px", paddingLeft: "12px" }}>
                  Employee Email
                </th>
                <th style={{ width: "150px", paddingLeft: "12px" }}>
                  Assigned product
                </th>
                <th style={{ width: "176px", textAlign: "center" }}>
                  Added Date
                </th>
                <th style={{ width: "231px", textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {teamTableCardData.map((data, id) => (
                <tr key={id}>
                  <td style={{ width: "35px", textAlign: "center" }}>
                    {/* <input type="checkbox" name="employee"/>*/}
                    <label htmlFor="employee">{id++ + 1}</label>
                  </td>
                  <td style={{ width: "215px", paddingLeft: "12px" }}>
                    {data.name}
                  </td>
                  <td style={{ width: "305px", paddingLeft: "12px" }}>
                    {data.email}
                  </td>
                  <td style={{ width: "150px", paddingLeft: "12px" }}>
                    {data.products}
                  </td>
                  <td style={{ width: "176px", textAlign: "center" }}>
                    {giveDate(data.createdAt)}
                  </td>
                  <td className="td-buttons" style={{ width: "130px", textAlign: "center" }}>
                    <button>
                      <FontAwesomeIcon
                        onClick={() => {
                          onDelete(data._id);
                        }}
                        icon={faTrash}
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                    {/* <button>
                            <FontAwesomeIcon icon={faSnowflake} style={{cursor:'pointer'}}/>
                        </button> */}
                    <button>
                      <FontAwesomeIcon
                        icon={faPencil}
                        onClick={() => onEdit(data)}
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p
            style={{
              display:"block",
              width: "fit-content",
              whiteSpace: "wrap",
              padding: "50px 20px",
              fontSize:"0.9rem",
              color: "grey",
              textAlign: "center",
              margin:"auto"
            }}
          >
            There is no team member added, please add the team member!
          </p>
        )}
      </div>

      {adding ? (
        <Modal>
          <AddUser
            userData={(value) => {
              setAdding(false);
            }}
          />
        </Modal>
      ) : (
        ""
      )}
      {deleting ? (
        <BigModal
          style={{ width: "425px", height: "290px", marginTop: "254.15px" }}
        >
          <DeleteUser
            id={currentdeleteid.current}
            deleteData={(value) => {
              setDeleting(false);
            }}
            apiLink={TeamTable.deleteSubClients}
            title={"user"}
          />
        </BigModal>
      ) : (
        ""
      )}
      {editing ? (
        <Modal>
          <EditUser
            editData={(value) => {
              setEditing(false);
            }}
            edit={editData}
          />
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default TeamTableCard;
