import React, { useRef, useState } from "react";
import "./AddUser.css";

import { TeamTable } from "../../api";
import BigModal from "../Form/BigModal";
import ErrorMessage from "../Error/ErrorMessage";

import add from "../../Assets/add.png";

function AddUser(props) {
  const name = useRef();
  const password = useRef();
  const email = useRef();
  const message = useRef();
  const [passwordError, setPasswordError] = useState(false);
  async function AddSubClient(bodyData) {
    try {
      const raw = await fetch(`${TeamTable.inviteSubclient}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify(bodyData),
      });
      let res = await raw.json();
      if (res.status !== "success") {
        // console.log(res.message);
        throw res;
      }
      return res;
    } catch (err) {
      throw err;
    }
  }
  function onSubmit() {
    const bodyData = {
      // 'name':name.current?.value,
      email: email.current?.value,
      // 'password':password.current?.value,
    };
    AddSubClient(bodyData)
      .then((response) => {
        message.current = "Invitation Sent!";
        setPasswordError(true);
        email.current.value = "";
      })
      .catch((err) => {
        message.current = err.message;
        console.log(err);
        setPasswordError(true);
      });
  }
  return (
    <div className="addUser">
      <div className="header-container">
        <h2>Invite User</h2>
      </div>
      <div className="input-container">
        {/* <input type='text' placeholder='Name' ref={name} /> */}
        <input type="email" placeholder="Email" ref={email} />
        {/* <input type='password' placeholder='Password' ref={password}/> */}
      </div>
      <div className="footer-container">
        <div className="btn" onClick={() => props.userData(false)}>
          <button className="btn" style={{ background: add }}>
            Cancel
          </button>
        </div>
        <div className="btn" onClick={onSubmit}>
          {/* <img src={add} alt="plus-icon"/> */}
          <button className="btn" style={{ background: add }}>
            Invite
          </button>
        </div>
      </div>
      {passwordError ? (
        <BigModal
          style={{ width: "425px", height: "256px", marginTop: "251.15px" }}
        >
          <ErrorMessage
            closeError={() => {
              setPasswordError(false);
            }}
            message={message.current}
          />
        </BigModal>
      ) : (
        ""
      )}
    </div>
  );
}

export default AddUser;
